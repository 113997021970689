import { styled } from '@mui/material/styles';
import { Fab, FabProps } from '@mui/material';

interface FabOutlinedProps extends FabProps {
  themeColor?: string;
}
const FabOutlined = styled((props: FabOutlinedProps) => (
  <Fab variant="extended" color="default" {...props} />
))(
  ({ theme, themeColor = theme.palette.primary.main }) => `
  fontWeight: bold;
  width: 80%;
  max-width: 20rem;
  color: ${themeColor};
  background-color: ${theme.palette.primary.contrastText};
  border: solid;
  borderWidth: 2px;
  border-color: ${themeColor};
  margin: 1rem;
`
);

export default FabOutlined;
