export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

let installPromptEvent: BeforeInstallPromptEvent | undefined;

export const beforeInstallPromptEventListener = (e: Event) => {
  installPromptEvent = e as BeforeInstallPromptEvent;
};

export default installPromptEvent;
