import React, { useEffect, useState } from 'react';

import { PublicCounselor } from '@libs/share/graphql-interfaces/typed-document-node';
import { uniqueArray } from 'lib/utils';
import { isPublicCounselor } from '../../graphql/discriminator';
import API from 'services/graphql-service';
import CounselorInSearch from 'components/CounselorInSearch';
import logger from 'lib/logger';
import { putUser } from 'controllers';
import { useProfile } from 'db';
import FlexContainer from 'layouts/FlexContainer';
import { Avatar, Box, Container, Typography } from '@mui/material';
import RenderLink from '@web/components/RenderLink';
import PagePaths from '@web/pagepaths';
import FabOutlined from '@web/components/FabOutlined';
import logoAiCounselor from 'assets/images/omamori-ai-counselor.svg';

const Search: React.FC = () => {
  const profile = useProfile();
  const [counselors, setCounselors] = useState<PublicCounselor[]>([]);

  console.log({ profile });

  useEffect(() => {
    // TODO: ページネーション対応
    API.listActiveCounselors({ limit: 30 })
      .then((res) => {
        if (res?.counselors) {
          const counselors = res?.counselors.filter(isPublicCounselor);
          counselors.forEach(putUser);
          setCounselors(uniqueArray(counselors));
        }
      })
      .catch((err) => logger.error(err));
  }, []);

  return (
    <FlexContainer component={Container} maxWidth="sm" sx={{ padding: 1 }}>
      <FlexContainer
        sx={{
          boxShadow: 4,
          borderRadius: 3,
          margin: 1,
          padding: 2,
          flex: 0,
          maxHeight: 200,
          display:
            !profile ||
            (profile?.privateUser?.__typename === 'PrivatePatient' &&
              profile.privateUser.aiAssistantId)
              ? 'none'
              : undefined,
        }}
        component={RenderLink(PagePaths.aiCounselor)}
      >
        <Box sx={{ display: 'flex' }}>
          <Avatar
            src={logoAiCounselor}
            sx={{ width: '5rem', height: '5rem', mr: 2 }}
          >
            コマちゃん
          </Avatar>
          <Box>
            <Typography variant="h6">
              Omamori AI
              アシスタント『コマちゃん』があなたが抱える問題や気になる悩みを解決できるようにサポートしてくれます。
            </Typography>
          </Box>
        </Box>
        <FabOutlined
          size="large"
          sx={{ margin: 1, marginBottom: 0, width: '100%' }}
        >
          <Typography variant="h6" whiteSpace="nowrap">
            コマちゃんを有効にする
          </Typography>
        </FabOutlined>
      </FlexContainer>
      {counselors.map((counselor) => (
        <CounselorInSearch counselor={counselor} key={counselor.id} />
      ))}
    </FlexContainer>
  );
};

export default Search;
