const STAGE = process.env.NODE_ENV;
const ENV_STAGE = process.env.REACT_APP_STAGE;
const stage = ENV_STAGE || STAGE;
/** ステージ判定を行う
 *  デフォルトだと test
 *  指定する場合は ENV_STAGE 環境変数に指定して、 `src/assets/awsConfig.${ENV_STAGE}.json` を用意する
 */
const availableStages = ['production', 'development', 'test'];

const checkStage = () => {
  const currentStage = availableStages.includes(stage) ? stage : 'test';
  // We must not import from './logger' as it might cause the circular reference.
  // console.log('current stage: ' + currentStage);
  return currentStage;
};

export default checkStage;
