import { useLiveQuery } from 'dexie-react-hooks';

import {
  dbGetConfig,
  dbGetInbox,
  getNotifications,
  getProfile,
  getTalkMessages,
  getTemp,
  getTimeline,
  NotificationModel,
  putTemp,
} from 'db';
import { initialConfig } from 'db/db';
import { useEffect, useState } from 'react';

export const useProfile = () => useLiveQuery(getProfile);

export const useInbox = () => useLiveQuery(dbGetInbox);
export const useConfig = () => useLiveQuery(dbGetConfig, [], initialConfig);

export const useNotification = () =>
  useLiveQuery(getNotifications, [], new Array<NotificationModel>());

export const useTalkMessages = (roomId: string) =>
  useLiveQuery(() => getTalkMessages(roomId));
export const useTimeline = () => useLiveQuery(() => getTimeline());

export const useTemp = (key: string) => useLiveQuery(() => getTemp(key));
export const useTempState = (key: string, initialValue?: string) => {
  const [temp, setTemp] = useState<string>(initialValue ?? '');

  useEffect(() => {
    getTemp(key).then((value) => value && setTemp(value));
  }, [key]);
  useEffect(() => {
    if (temp !== null) putTemp(key, temp);
  }, [key, temp]);

  return [temp, setTemp] as const;
};
