import { RouteProps, Redirect, Route } from 'react-router-dom';

import logger from 'lib/logger';

const RedirectRoute = (props: RouteProps & { to: string }) => {
  if (props.path === props.to) {
    logger.log({ msg: 'RedirectRoute', to: props.to });
    return <Route {...props} />;
  } else {
    return <Redirect to={props.to} />;
  }
};

export default RedirectRoute;
