import { useState, useCallback } from 'react';

function useBooleanState(init: boolean) {
  const [state, setState] = useState(init);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return [state, setTrue, setFalse] as const;
}

export function useUndefinedBooleanState(init?: boolean) {
  const [state, setState] = useState(init);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return [state, setTrue, setFalse] as const;
}

export default useBooleanState;
