import checkStage from 'lib/checkStage';

const isProduction = checkStage() === 'production';

class Logger {
  log(message: unknown, ...optionalParams: unknown[]) {
    if (isProduction) return;
    console.log(message, ...optionalParams);
  }

  error(message: unknown, ...optionalParams: unknown[]) {
    if (isProduction) return;
    console.error(message, ...optionalParams);
  }

  warn(message: unknown, ...optionalParams: unknown[]) {
    if (isProduction) return;
    console.warn(message, ...optionalParams);
  }
}

const logger = new Logger();
export default logger;
