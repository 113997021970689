import { Auth } from '@aws-amplify/auth';

import { getProfile, clearProfile, putProfile, dbUpdateConfig } from 'db';

import { convertPhoneNumberToGlobal } from 'lib/utils';
import { group } from 'env';
import logger from 'lib/logger';
import { getUser } from 'controllers';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import loadAwsConfig, { getAwsConfig } from 'awsConfig';

import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloLink } from '@apollo/client/core';
import { isPrivateUser } from '@web/graphql/discriminator';

loadAwsConfig();

export interface CognitoAttributes {
  email: string;
  email_verified: boolean;
  phone_number: string;
  phone_number_verified: boolean;
  birthdate?: string;
  family_name?: string;
  given_name?: string;
  gender?: string;
  'custom:stripe': string;
  'custom:family_name_kana': string;
  'custom:given_name_kana': string;
  sub: string;
}

export type OmamoriCognitoUser = Partial<CognitoUser> & {
  [key: string]: unknown;
  attributes: CognitoAttributes;
  id: string;
  username: string;
};

// Amplify の Auth をラップして IndexedDB につなぐサービス
class AuthService {
  static updateProfile = async () => {
    try {
      const cognitoUser = await AuthService.currentUserInfo();
      const id = cognitoUser.attributes.sub;
      const user = await getUser(id);
      const privateUser = user && isPrivateUser(user) ? user : undefined;
      const newProfile = { id, cognitoUser, privateUser };
      logger.log(newProfile);

      return putProfile(newProfile);
    } catch (error) {
      logger.error(error);
      return;
    }
  };

  static updatePrivateUser = async () => {
    try {
      const oldProfile = await getProfile();
      if (oldProfile) {
        const id = oldProfile.cognitoUser.attributes.sub;
        const user = await getUser(id);
        const privateUser = user && isPrivateUser(user) ? user : undefined;
        const newProfile = {
          id,
          cognitoUser: oldProfile.cognitoUser,
          privateUser,
        };
        logger.log(newProfile);
        return putProfile(newProfile);
      } else {
        logger.warn('no profile.');
        return;
      }
    } catch (error) {
      logger.error(error);
      return;
    }
  };

  static updateUserAttributes = async (
    attributes: Partial<CognitoAttributes>
  ) => {
    try {
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, attributes);
      await AuthService.updateProfile();
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static signUp = async (
    username: string,
    password: string,
    phoneNumber: string,
    email: string
  ) => {
    const globalPhoneNumber = convertPhoneNumberToGlobal(phoneNumber);
    try {
      return Auth.signUp({
        username,
        password,
        attributes: {
          phone_number: globalPhoneNumber,
          email,
          'custom:group': group,
        },
      });
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static resendSignUp = async (username: string) => {
    try {
      await Auth.resendSignUp(username);
      logger.log('resend code succeeded');
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static signIn = async (username: string, password: string) => {
    await Auth.signIn(convertPhoneNumberToGlobal(username), password, {
      group: process.env.REACT_APP_TARGET as string,
    });
    return Promise.all([
      AuthService.updateProfile(),
      dbUpdateConfig({ acceptedTermsOfService: true }),
    ]);
  };

  static signOut = async () => Promise.all([Auth.signOut(), clearProfile()]);

  static verifyCurrentUserAttribute = async (name: string) => {
    try {
      await Auth.verifyCurrentUserAttribute(name);
      logger.log('resent code to' + name);
      return AuthService.updateProfile();
    } catch (error) {
      logger.error(error);
    }
  };

  static confirmSignUp = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
      logger.log('confirmSignup verification succeeded');
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  /** User info and current session */
  static currentAuthenticatedUser = async () => {
    try {
      const cognitoUser: OmamoriCognitoUser | null =
        await Auth.currentAuthenticatedUser();
      if (!cognitoUser) {
        clearProfile();
        throw new Error('No current user');
      }
      return cognitoUser;
    } catch (error) {
      logger.error(error);
      await AuthService.signOut();
      throw error;
    }
  };

  /** User info only */
  static currentUserInfo = async () => {
    const cognitoUser: OmamoriCognitoUser | null = await Auth.currentUserInfo();
    if (!cognitoUser) {
      clearProfile();
      throw new Error('No current user');
    }
    return cognitoUser;
  };

  static verifyCurrentUserAttributeSubmit = async (
    attr: string,
    code: string
  ) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attr, code);
      await AuthService.updateProfile();
      logger.log(`${attr} verification succeeded`);
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      await Auth.changePassword(cognitoUser, oldPassword, newPassword);
      logger.log('password changed');
      return;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  static getAuthToken = async () => {
    const cognitoUser = await AuthService.currentAuthenticatedUser();

    const session = cognitoUser.getSignInUserSession
      ? cognitoUser.getSignInUserSession()
      : null;
    const idToken = session?.getIdToken ? session.getIdToken() : null;
    const jwtToken = idToken?.getJwtToken ? idToken.getJwtToken() : null;

    if (jwtToken) {
      return jwtToken;
    } else {
      throw new Error('There is not valid auth token.');
    }
  };

  static getCognitoCredentialForAwsSdk = async () => {
    const awsConfig = await getAwsConfig();
    const jwtToken = await AuthService.getAuthToken();

    return fromCognitoIdentityPool({
      clientConfig: { region: awsConfig.Auth.region },
      identityPoolId: awsConfig.Auth.identityPoolId,
      logins: {
        [`cognito-idp.${awsConfig.Auth.region}.amazonaws.com/${awsConfig.Auth.userPoolId}`]:
          jwtToken ?? '',
      },
    });
  };

  static getApolloLinkForAppSync = async (): Promise<ApolloLink> => {
    const jwtToken = await AuthService.getAuthToken();
    const awsConfig = await getAwsConfig();

    const url = awsConfig.aws_appsync_graphqlEndpoint;
    const region = awsConfig.aws_appsync_region;
    const auth: AuthOptions = {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken,
    };

    const link = ApolloLink.from([
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink({ url, region, auth }),
    ]);
    return link;
  };

  static getApolloLinkForAppSyncApiKey = async (): Promise<ApolloLink> => {
    const awsConfig = await getAwsConfig();

    const url = awsConfig.aws_appsync_graphqlEndpoint;
    const region = awsConfig.aws_appsync_region;
    const auth: AuthOptions = {
      type: 'API_KEY',
      apiKey: awsConfig.aws_appsync_apiKey,
    };

    const link = ApolloLink.from([
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink({ url, region, auth }),
    ]);
    return link;
  };
}

export default AuthService;
