import { QualificationEvidence } from '@libs/share/graphql-interfaces/typed-document-node';
import { isConfirmedQualificationEvidence } from '@web/graphql/discriminator';

export const uniqueQualificationNames = (
  qualificationEvidences: QualificationEvidence[]
): string[] => {
  return [
    ...new Set(
      qualificationEvidences
        .map((evidence) => {
          if (isConfirmedQualificationEvidence(evidence)) {
            return evidence.qualificationName;
          } else {
            return undefined;
          }
        })
        .filter(
          (item): item is Exclude<typeof item, undefined> => item !== undefined
        )
    ),
  ].sort();
};
