import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { putReservation } from 'db';

import { PrivateInAppReservation } from '@libs/share/graphql-interfaces/typed-document-node';
import logger from 'lib/logger';
import API from 'services/graphql-service';
import { isPrivateInAppReservation } from '@web/graphql/discriminator';

export const listMyCalendarItems = async () => {
  try {
    const startTimestamp = dayjs().add(-1, 'day').unix();
    const limit = 100;
    return API.listSelfCalendarItems({
      limit,
      startTimestamp,
      endTimestamp: undefined,
      nextToken: undefined,
    });
  } catch (error) {
    logger.error(error);
  }
  return null;
};

export const listCounselorCalendarItems = async (id: string) => {
  const res = await API.listCounselorCalendarItems({
    counselorId: id,
    limit: 100,
    startTimestamp: dayjs().add(-1, 'day').unix(),
    endTimestamp: undefined,
    nextToken: undefined,
  });
  return res;
};

export const getReservations = async () => {
  try {
    const res = await listMyCalendarItems();
    if (res?.calendarItems) {
      const reservations = res?.calendarItems.filter((item) =>
        isPrivateInAppReservation(item)
      ) as PrivateInAppReservation[];
      reservations.forEach(putReservation);
      return reservations;
    }
    return null;
  } catch (error) {
    logger.error(error);
    return null;
  }
};
