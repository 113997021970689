import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import loadable from '@loadable/component';

import PagePaths from 'pagepaths';
import { targetIsPublicPatient } from 'env';
import Home from 'pages/app/Home';

const AiCounselor = loadable(() => import('pages/app/search/AiCounselor'));
const CreatePlan = loadable(() => import('pages/app/mypage/CreatePlan'));
const CreateAvailableTime = loadable(
  () => import('pages/app/mypage/CreateAvailableTime')
);
const Comment = loadable(() => import('pages/app/timeline/Comment'));
const SentComment = loadable(() => import('pages/app/timeline/SentComment'));
const Talk = loadable(() => import('pages/app/inbox/Talk'));
const MyCalendar = loadable(() => import('pages/app/mypage/MyCalendar'));
const MyClients = loadable(() => import('pages/app/mypage/MyClients'));
const Client = loadable(() => import('pages/app/mypage/ClientDetail'));
const MyReservations = loadable(
  () => import('pages/app/mypage/MyReservations')
);
const Reservation = loadable(() => import('pages/app/mypage/Reservation'));
const MyPlan = loadable(() => import('pages/app/mypage/MyPlan'));
const Setting = loadable(() => import('pages/app/mypage/Setting'));
const News = loadable(() => import('pages/app/notification/News'));
const Concern = loadable(() => import('pages/app/timeline/Concern'));
const AfterConcern = loadable(() => import('pages/app/mypage/AfterConcern'));
const Reserve = loadable(() => import('pages/app/reservation/Reserve'));
const ReserveComplete = loadable(
  () => import('pages/app/reservation/Complete')
);
const UpdateProgressNote = loadable(
  () => import('pages/app/mypage/UpdateProgressNote')
);
const CreatePostscript = loadable(
  () => import('pages/app/mypage/CreatePostscript')
);

const NotFound = loadable(() => import('pages/NotFound'));

const AppSwitch: React.FC = () => (
  <Switch>
    <Redirect exact from={PagePaths.app} to={PagePaths.inbox} />
    <Route
      exact
      path={[
        PagePaths.inbox,
        PagePaths.search,
        PagePaths.timeline,
        PagePaths.notification,
        PagePaths.mypage,
      ]}
      component={Home}
    />

    {/** Common */}
    <Route exact path={PagePaths.setting} component={Setting} />
    <Route exact path={PagePaths.myCalendar} component={MyCalendar} />
    <Route exact path={PagePaths.talk + '/:id'} component={Talk} />
    <Route exact path={PagePaths.news + '/:id+'} component={News} />

    {targetIsPublicPatient ? (
      /** Patient */
      <>
        <Route exact path={PagePaths.aiCounselor} component={AiCounselor} />
        <Route exact path={PagePaths.reserve} component={Reserve} />
        <Route exact path={PagePaths.concern} component={Concern} />
        <Route exact path={PagePaths.afterConcern} component={AfterConcern} />
        <Route
          exact
          path={PagePaths.reserveCompleted}
          component={ReserveComplete}
        />
      </>
    ) : (
      /** Counselor */
      <>
        <Route exact path={PagePaths.myPlan} component={MyPlan} />
        <Route exact path={PagePaths.createPlan} component={CreatePlan} />
        <Route
          exact
          path={PagePaths.createAvailableTime}
          component={CreateAvailableTime}
        />
        <Route exact path={`${PagePaths.myClients}`} component={MyClients} />
        <Route exact path={`${PagePaths.myClients}/:id`} component={Client} />
        <Route
          exact
          path={`${PagePaths.myReservations}`}
          component={MyReservations}
        />
        <Route
          exact
          path={`${PagePaths.reservation}/:id`}
          component={Reservation}
        />
        <Route exact path={`${PagePaths.comment}/:id`} component={Comment} />
        <Route exact path={PagePaths.sentComment} component={SentComment} />
        <Route
          exact
          path={`${PagePaths.progressNote}/:progressNoteId`}
          component={UpdateProgressNote}
        />
        <Route
          exact
          path={`${PagePaths.postscript}/:progressNoteId`}
          component={CreatePostscript}
        />
      </>
    )}
    <Route component={NotFound} />
  </Switch>
);

export default AppSwitch;
