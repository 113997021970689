import { dbGetLatestMessage, dbGetMessage, dbPutMessage, updateRoom } from 'db';
import API from 'services/graphql-service';
import logger from 'lib/logger';
import {
  Chat,
  Message,
  MutationPostChatArgs,
  MutationPostCommentArgs,
  MutationPostConcernArgs,
} from '@libs/share/graphql-interfaces/typed-document-node';
import {
  isTextMessage,
  isComment,
  isConcern,
  isFileMessage,
  isImageMessage,
} from '@web/graphql/discriminator';

export const getMessage = async (id: string) => {
  try {
    const dbMessage = await dbGetMessage(id);
    if (dbMessage) return dbMessage;
    const gqlMessage = await API.getMessage({ messageId: id });
    if (gqlMessage) {
      dbPutMessage(gqlMessage);
      return gqlMessage;
    }
    throw new Error('Message not found');
  } catch (error) {
    logger.error(error, { id });
    return null;
  }
};
const onReceiveMessage = async (message: Message) => {
  try {
    if (
      isTextMessage(message) ||
      isFileMessage(message) ||
      isImageMessage(message) ||
      isComment(message)
    ) {
      const roomId = message.fromId;
      updateRoom(roomId, message);
    }
    await dbPutMessage(message);
    return;
  } catch (error) {
    logger.error(error);
    return;
  }
};
const onSendMessage = async (message: Message) => {
  try {
    if (
      isTextMessage(message) ||
      isFileMessage(message) ||
      isImageMessage(message) ||
      isComment(message)
    ) {
      const roomId = message.destId;
      updateRoom(roomId, message);
    }
    await dbPutMessage(message);
    return;
  } catch (error) {
    logger.error(error);
    return;
  }
};

/** API 系 */
export const postChat = async (props: MutationPostChatArgs) => {
  const chat = await API.postChat(props);
  if (chat) onSendMessage(chat);
  else throw new Error('Continuous message throwing');
};
export const postComment = async (props: MutationPostCommentArgs) => {
  try {
    const comment = await API.postComment(props);
    if (comment) onSendMessage(comment);
  } catch (error) {
    logger.error(error);
  }
};
export const postConcern = async (props: MutationPostConcernArgs) => {
  const concern = await API.postConcern(props);
  if (concern) onSendMessage(concern[0]);
};
export const listTimelineConcerns = async (nextToken?: string) => {
  const res = await API.listTimelineConcerns({ limit: 50, nextToken });
  if (res?.concerns) {
    res.concerns.forEach((concern) => {
      if (isConcern(concern)) {
        dbPutMessage(concern);
      }
    });
    return res.concerns;
  } else {
    return [];
  }
};

export const syncAllMessages = async () => {
  try {
    const lastMessage = await dbGetLatestMessage();
    const lastSync = lastMessage ? lastMessage.timestamp : 0;
    const graphQlProps = { limit: 100, lastSync };
    const incomingMessages = await API.listIncomingMessagesAll(
      graphQlProps
    ).then((messages) => {
      messages.forEach(onReceiveMessage);
      return messages;
    });
    const outgoingMessages = await API.listOutgoingMessagesAll(
      graphQlProps
    ).then((messages) => {
      messages.forEach(onSendMessage);
      return messages;
    });
    return [...incomingMessages, ...outgoingMessages];
  } catch (error) {
    logger.error(error);
  }
};

export const subscribeMessage = async (
  destId: string,
  cb: (_message: Chat) => void
) => {
  try {
    const subscription = API.postedChat({ destId }, (message) => {
      if (message) {
        onReceiveMessage(message);
        return cb(message);
      }
    });
    logger.log('start subscribe message', { destId });
    return subscription;
  } catch (error) {
    logger.error(error);
  }
};
