import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AiAssistant = User & {
  __typename?: 'AiAssistant';
  ban: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
  group: GroupName;
  id: Scalars['ID'];
  profileImage?: Maybe<Scalars['String']>;
  registeredTimestamp: Scalars['Int'];
  updatedTimestamp: Scalars['Int'];
  username: Scalars['String'];
  version: Scalars['Int'];
};

export type Assignee = {
  __typename?: 'Assignee';
  assignedTimestamp: Scalars['Int'];
  assigneeId: Scalars['String'];
};

export type AttendVideoMeetingResponse = {
  __typename?: 'AttendVideoMeetingResponse';
  attendee?: Maybe<Scalars['AWSJSON']>;
  meeting?: Maybe<Scalars['AWSJSON']>;
};

export type AvailableTime = Service & {
  __typename?: 'AvailableTime';
  counselorId: Scalars['String'];
  endTimestamp: Scalars['Int'];
  id: Scalars['ID'];
  registeredTimestamp: Scalars['Int'];
  reserveDeadlineTimestamp: Scalars['Int'];
  serviceGroup: ServiceGroupName;
  serviceType: ServiceType;
  startTimestamp: Scalars['Int'];
};

export type AvailableTimesResponse = {
  __typename?: 'AvailableTimesResponse';
  availableTimes: Array<Maybe<AvailableTime>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type BaseQualificationEvidence = {
  evidenceConfirmationStatus: EvidenceConfirmationStatus;
};

export type CalendarItem =
  | AvailableTime
  | PrivateInAppReservation
  | PublicInAppReservation;

export enum CancelReason {
  ClientRequest = 'clientRequest',
  CounselorRequest = 'counselorRequest',
  DoubleBooking = 'doubleBooking',
  OmamoriRequest = 'omamoriRequest',
  SystemFailure = 'systemFailure',
  Unpaid = 'unpaid',
}

export type CancelReservationResponse = {
  __typename?: 'CancelReservationResponse';
  refundPrice?: Maybe<Scalars['Int']>;
};

export type Chat = FileMessage | ImageMessage | TextMessage;

export type CheckQualificationEvidenceResponse = {
  __typename?: 'CheckQualificationEvidenceResponse';
  ticketId?: Maybe<Scalars['String']>;
};

export type Comment = Message & {
  __typename?: 'Comment';
  concernId: Scalars['String'];
  destId: Scalars['String'];
  fromId: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type Concern = Message & {
  __typename?: 'Concern';
  category: Scalars['String'];
  destId: Scalars['String'];
  fromId: Scalars['String'];
  genre: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type ConcernsResponse = {
  __typename?: 'ConcernsResponse';
  concerns: Array<Maybe<Concern>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ConfirmedCppQualificationEvidence = BaseQualificationEvidence & {
  __typename?: 'ConfirmedCPPQualificationEvidence';
  confirmedTimestamp?: Maybe<Scalars['Int']>;
  evidenceConfirmationStatus: EvidenceConfirmationStatus;
  qualificationName?: Maybe<Scalars['String']>;
};

export type ConfirmedCpQualificationEvidence = BaseQualificationEvidence & {
  __typename?: 'ConfirmedCPQualificationEvidence';
  confirmedTimestamp?: Maybe<Scalars['Int']>;
  evidenceConfirmationStatus: EvidenceConfirmationStatus;
  expiredTimestamp: Scalars['Int'];
  qualificationName?: Maybe<Scalars['String']>;
};

export type CounselingRoom = {
  __typename?: 'CounselingRoom';
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  adminCounselor: Scalars['String'];
  createdTimestamp: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileImage?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedTimestamp?: Maybe<Scalars['Int']>;
};

export type CreateCounselingJwtResponse = {
  __typename?: 'CreateCounselingJwtResponse';
  nextAvailableAt?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  tokenIssuanceDeniedReason?: Maybe<TokenIssuanceDeniedReason>;
};

export type CreateCounselingRoomResponse = {
  __typename?: 'CreateCounselingRoomResponse';
  counselingRoomId?: Maybe<Scalars['String']>;
};

export type CreatePaymentAccountOnboardingUrlResponse = {
  __typename?: 'CreatePaymentAccountOnboardingUrlResponse';
  url?: Maybe<Scalars['String']>;
};

export type CreatePaymentSessionResponse = {
  __typename?: 'CreatePaymentSessionResponse';
  doubleCounseling?: Maybe<DoubleCounseling>;
  url?: Maybe<Scalars['String']>;
};

export type CreateVideoMeetingResponse = {
  __typename?: 'CreateVideoMeetingResponse';
  attendee?: Maybe<Scalars['AWSJSON']>;
  meeting?: Maybe<Scalars['AWSJSON']>;
};

export enum DoubleCounseling {
  Pass = 'pass',
  Reject = 'reject',
  Warning = 'warning',
}

export enum EvidenceConfirmationStatus {
  Confirmed = 'confirmed',
  Uploaded = 'uploaded',
}

export type FileMessage = Message & {
  __typename?: 'FileMessage';
  destId: Scalars['String'];
  fromId: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  originalName: Scalars['String'];
  timestamp: Scalars['Int'];
};

export enum GroupName {
  AiAssistants = 'aiAssistants',
  Counselors = 'counselors',
  Patients = 'patients',
}

export type ImageMessage = Message & {
  __typename?: 'ImageMessage';
  destId: Scalars['String'];
  fromId: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  originalName: Scalars['String'];
  thumbnailKey?: Maybe<Scalars['String']>;
  timestamp: Scalars['Int'];
};

export type Message = {
  destId: Scalars['String'];
  fromId: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['Int'];
};

export type MessagesResponse = {
  __typename?: 'MessagesResponse';
  messages: Array<Maybe<Message>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addPostscriptToProgressNote?: Maybe<ProgressNote>;
  attendVideoMeeting?: Maybe<AttendVideoMeetingResponse>;
  cancelReservation?: Maybe<CancelReservationResponse>;
  checkQualificationEvidence?: Maybe<CheckQualificationEvidenceResponse>;
  createAvailableTime?: Maybe<AvailableTime>;
  createCounselingRoom?: Maybe<CreateCounselingRoomResponse>;
  createPlan?: Maybe<Plan>;
  createProgressNote?: Maybe<ProgressNote>;
  createUser?: Maybe<PrivateUser>;
  createVideoMeeting?: Maybe<CreateVideoMeetingResponse>;
  deleteAvailableTime?: Maybe<AvailableTime>;
  deletePlan?: Maybe<Plan>;
  fixProgressNote?: Maybe<ProgressNote>;
  postChat?: Maybe<Chat>;
  postComment?: Maybe<Comment>;
  postConcern?: Maybe<Array<Maybe<Concern>>>;
  updateCounselingRoom?: Maybe<UpdateCounselingRoomResponse>;
  updateCounselor?: Maybe<PrivateCounselor>;
  updatePatient?: Maybe<PrivatePatient>;
  updatePlan?: Maybe<Plan>;
  updateProgressNote?: Maybe<ProgressNote>;
  updateReservation?: Maybe<PrivateInAppReservation>;
};

export type MutationAddPostscriptToProgressNoteArgs = {
  postscript: Scalars['String'];
  progressNoteId: Scalars['String'];
};

export type MutationAttendVideoMeetingArgs = {
  reservationId: Scalars['String'];
  token: Scalars['String'];
};

export type MutationCancelReservationArgs = {
  reservationId: Scalars['String'];
};

export type MutationCheckQualificationEvidenceArgs = {
  confirm: Scalars['Boolean'];
  counselorId: Scalars['String'];
  expiredTimestamp?: InputMaybe<Scalars['Int']>;
  qualificationName?: InputMaybe<Scalars['String']>;
  qualificationRegistrationNumber?: InputMaybe<Scalars['Int']>;
  ticketId: Scalars['String'];
};

export type MutationCreateAvailableTimeArgs = {
  endTimestamp: Scalars['Int'];
  reserveDeadlineTimestamp?: InputMaybe<Scalars['Int']>;
  serviceType?: InputMaybe<ServiceType>;
  startTimestamp: Scalars['Int'];
};

export type MutationCreateCounselingRoomArgs = {
  adminCounselor?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MutationCreatePlanArgs = {
  minutes: Scalars['Int'];
  planDescription: Scalars['String'];
  planName: Scalars['String'];
  price: Scalars['Int'];
  serviceType: ServiceType;
  serviceUnit: ServiceUnit;
};

export type MutationCreateProgressNoteArgs = {
  assessment?: InputMaybe<Scalars['String']>;
  counselingPlan?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['String']>;
  medicationStatus?: InputMaybe<Scalars['String']>;
  objectiveData?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  reservationId: Scalars['String'];
  subjectiveData?: InputMaybe<Scalars['String']>;
};

export type MutationCreateUserArgs = {
  birthDate?: InputMaybe<Scalars['Int']>;
  counselingRoomId?: InputMaybe<Scalars['String']>;
  experts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  stripeId?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type MutationCreateVideoMeetingArgs = {
  reservationId: Scalars['String'];
  token: Scalars['String'];
};

export type MutationDeleteAvailableTimeArgs = {
  availableTimeId: Scalars['String'];
};

export type MutationDeletePlanArgs = {
  planId: Scalars['String'];
};

export type MutationFixProgressNoteArgs = {
  assessment?: InputMaybe<Scalars['String']>;
  counselingPlan?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['String']>;
  medicationStatus?: InputMaybe<Scalars['String']>;
  objectiveData?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  progressNoteId: Scalars['String'];
  subjectiveData?: InputMaybe<Scalars['String']>;
};

export type MutationPostChatArgs = {
  attachments: Scalars['String'];
  chatId?: InputMaybe<Scalars['String']>;
  destId: Scalars['String'];
  fromId?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};

export type MutationPostCommentArgs = {
  attachments: Scalars['String'];
  concernId: Scalars['String'];
  destId: Scalars['String'];
  timestamp?: InputMaybe<Scalars['Int']>;
};

export type MutationPostConcernArgs = {
  attachments: Scalars['String'];
  target?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateCounselingRoomArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  counselingRoomId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateCounselorArgs = {
  description?: InputMaybe<Scalars['String']>;
  experts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePatientArgs = {
  gender?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  useAiAssistant?: InputMaybe<Scalars['Boolean']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePlanArgs = {
  planDescription?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  planName?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateProgressNoteArgs = {
  assessment?: InputMaybe<Scalars['String']>;
  counselingPlan?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['String']>;
  medicationStatus?: InputMaybe<Scalars['String']>;
  objectiveData?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  progressNoteId: Scalars['String'];
  subjectiveData?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateReservationArgs = {
  reservationId: Scalars['String'];
  serviceType?: InputMaybe<ServiceType>;
  startTimestamp?: InputMaybe<Scalars['Int']>;
};

export type Plan = {
  __typename?: 'Plan';
  adminId: Scalars['String'];
  adminType: PlanAdministrator;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  minutes: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  public: Scalars['Boolean'];
  registeredTimestamp: Scalars['Int'];
  serviceType: ServiceType;
  serviceUnit: ServiceUnit;
  stripePriceId: Scalars['String'];
  stripeProductId: Scalars['String'];
  updatedTimestamp?: Maybe<Scalars['Int']>;
};

export enum PlanAdministrator {
  CounselingRoom = 'counselingRoom',
  Counselor = 'counselor',
}

export type PlansResponse = {
  __typename?: 'PlansResponse';
  nextToken?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<Maybe<Plan>>>;
};

export type PresignedUrlResponse = {
  __typename?: 'PresignedUrlResponse';
  presignedUrl?: Maybe<Scalars['String']>;
};

export type PrivateCalendarItem = AvailableTime | PrivateInAppReservation;

export type PrivateCalendarItemsResponse = {
  __typename?: 'PrivateCalendarItemsResponse';
  calendarItems: Array<Maybe<PrivateCalendarItem>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PrivateCounselor = User & {
  __typename?: 'PrivateCounselor';
  ban: Scalars['Boolean'];
  birthDate?: Maybe<Scalars['Int']>;
  counselingRoomId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  experts?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  group: GroupName;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  qualificationEvidences?: Maybe<Array<Maybe<QualificationEvidence>>>;
  registeredTimestamp: Scalars['Int'];
  stripeCurrentDisabledReason?: Maybe<Scalars['String']>;
  stripeFutureDisabledReason?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  updatedTimestamp: Scalars['Int'];
  username: Scalars['String'];
  version: Scalars['Int'];
  zipCode?: Maybe<Scalars['String']>;
};

export type PrivateInAppReservation = {
  __typename?: 'PrivateInAppReservation';
  cancelReason?: Maybe<CancelReason>;
  cancelRequestedTimestamp?: Maybe<Scalars['Int']>;
  chargeId?: Maybe<Scalars['String']>;
  checkoutSessionId?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  counselingComment?: Maybe<Scalars['String']>;
  counselingContent?: Maybe<Scalars['String']>;
  counselingMajorClassification?: Maybe<Scalars['String']>;
  counselingMinorClassification?: Maybe<Scalars['String']>;
  counselorChargeCompleteTimestamp?: Maybe<Scalars['Int']>;
  counselorChargeInsufficientPrice?: Maybe<Scalars['Int']>;
  counselorChargePrice?: Maybe<Scalars['Int']>;
  counselorChargeRequestedTimestamp?: Maybe<Scalars['Int']>;
  counselorChargeStatus?: Maybe<TransactionStatus>;
  counselorChargeStripeObjectId?: Maybe<Scalars['String']>;
  counselorChargeUrl?: Maybe<Scalars['String']>;
  counselorChargeUrlExpiredTimestamp?: Maybe<Scalars['Int']>;
  counselorId: Scalars['String'];
  counselorStripeId?: Maybe<Scalars['String']>;
  endTimestamp: Scalars['Int'];
  expiredTimestamp?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  omamoriPlatformFee?: Maybe<Scalars['Int']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  planId: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  proposedUserType: ProposedUserType;
  refundCompleteTimestamp?: Maybe<Scalars['Int']>;
  refundPrice?: Maybe<Scalars['Int']>;
  refundRequestedTimestamp?: Maybe<Scalars['Int']>;
  refundStatus?: Maybe<TransactionStatus>;
  refundStripeObjectId?: Maybe<Scalars['String']>;
  registeredTimestamp: Scalars['Int'];
  reservationStatus: ReservationStatus;
  reservedTimestamp: Scalars['Int'];
  serviceType: ServiceType;
  serviceUnit: ServiceUnit;
  settlementId?: Maybe<Scalars['String']>;
  settlementRegisteredTimestamp?: Maybe<Scalars['Int']>;
  settlementStatus?: Maybe<SettlementStatus>;
  settlementUpdatedTimestamp?: Maybe<Scalars['Int']>;
  startTimestamp: Scalars['Int'];
  transferCompleteTimestamp?: Maybe<Scalars['Int']>;
  transferPrice?: Maybe<Scalars['Int']>;
  transferRequestedTimestamp?: Maybe<Scalars['Int']>;
  transferStatus?: Maybe<TransactionStatus>;
  transferStripeObjectId?: Maybe<Scalars['String']>;
  updatedTimestamp?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  urlExpiredTimestamp?: Maybe<Scalars['Int']>;
  videoMeetingInfo?: Maybe<VideoMeetingInfo>;
};

export type PrivateInAppReservationsResponse = {
  __typename?: 'PrivateInAppReservationsResponse';
  nextToken?: Maybe<Scalars['String']>;
  reservations: Array<Maybe<PrivateInAppReservation>>;
};

export type PrivatePatient = User & {
  __typename?: 'PrivatePatient';
  aiAssistantId?: Maybe<Scalars['String']>;
  ban: Scalars['Boolean'];
  birthDate?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  group: GroupName;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  registeredTimestamp: Scalars['Int'];
  updatedTimestamp: Scalars['Int'];
  username: Scalars['String'];
  version: Scalars['Int'];
  zipCode?: Maybe<Scalars['String']>;
};

export type PrivateUser = PrivateCounselor | PrivatePatient;

export type ProgressNote = {
  __typename?: 'ProgressNote';
  assessment?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  counselingMajorClassification: Scalars['String'];
  counselingMinorClassification: Scalars['String'];
  counselingPlan?: Maybe<Scalars['String']>;
  counselorId: Scalars['String'];
  id: Scalars['ID'];
  intervention?: Maybe<Scalars['String']>;
  medicationStatus?: Maybe<Scalars['String']>;
  objectiveData?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  postscript?: Maybe<Scalars['String']>;
  progressNoteStatus: ProgressNoteStatus;
  registeredTimestamp: Scalars['Int'];
  reservationId: Scalars['String'];
  savedTimestamp?: Maybe<Scalars['Int']>;
  subjectiveData?: Maybe<Scalars['String']>;
  updatedTimestamp?: Maybe<Scalars['Int']>;
};

export enum ProgressNoteStatus {
  Saved = 'saved',
  Temporary = 'temporary',
}

export enum ProposedUserType {
  Client = 'Client',
  Counselor = 'Counselor',
}

export type PublicCalendarItem = AvailableTime | PublicInAppReservation;

export type PublicCalendarItemsResponse = {
  __typename?: 'PublicCalendarItemsResponse';
  calendarItems: Array<Maybe<PublicCalendarItem>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PublicCounselor = User & {
  __typename?: 'PublicCounselor';
  counselingRoomId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  experts?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  group: GroupName;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  qualificationEvidences?: Maybe<Array<Maybe<QualificationEvidence>>>;
  username: Scalars['String'];
  version: Scalars['Int'];
};

export type PublicCounselorsResponse = {
  __typename?: 'PublicCounselorsResponse';
  counselors: Array<Maybe<PublicCounselor>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PublicInAppReservation = {
  __typename?: 'PublicInAppReservation';
  counselorId: Scalars['String'];
  endTimestamp: Scalars['Int'];
  expiredTimestamp?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  registeredTimestamp: Scalars['Int'];
  reservationStatus: ReservationStatus;
  reservedTimestamp: Scalars['Int'];
  startTimestamp: Scalars['Int'];
};

export type PublicInAppReservationsResponse = {
  __typename?: 'PublicInAppReservationsResponse';
  nextToken?: Maybe<Scalars['String']>;
  reservations: Array<Maybe<PublicInAppReservation>>;
};

export type PublicPatient = User & {
  __typename?: 'PublicPatient';
  age?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  group: GroupName;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  version: Scalars['Int'];
};

export type PublicUser = PublicCounselor | PublicPatient;

export type QualificationEvidence =
  | ConfirmedCppQualificationEvidence
  | ConfirmedCpQualificationEvidence
  | UnconfirmedQualificationEvidence;

export enum QualificationEvidenceConfirmationStatus {
  Confirmed = 'confirmed',
  Denied = 'denied',
}

export type Query = {
  __typename?: 'Query';
  createCounselingJwt?: Maybe<CreateCounselingJwtResponse>;
  createGetObjectPresignedUrl?: Maybe<PresignedUrlResponse>;
  createGetObjectPresignedUrlByMessageId?: Maybe<PresignedUrlResponse>;
  createPaymentAccountOnboardingUrl?: Maybe<CreatePaymentAccountOnboardingUrlResponse>;
  createPaymentSession?: Maybe<CreatePaymentSessionResponse>;
  createPutObjectPresignedUrl?: Maybe<PresignedUrlResponse>;
  getCounselingRoom?: Maybe<CounselingRoom>;
  getCounselor?: Maybe<PublicCounselor>;
  getMessage?: Maybe<Message>;
  getPlan?: Maybe<Plan>;
  getProgressNote?: Maybe<ProgressNote>;
  getProgressNoteByReservationId?: Maybe<ProgressNote>;
  getReservation?: Maybe<Reservation>;
  getUser?: Maybe<User>;
  listActiveCounselors?: Maybe<PublicCounselorsResponse>;
  listCounselorAvailableTimes?: Maybe<AvailableTimesResponse>;
  listCounselorCalendarItems?: Maybe<PublicCalendarItemsResponse>;
  listCounselorPlans?: Maybe<PlansResponse>;
  listCounselorReservations?: Maybe<PublicInAppReservationsResponse>;
  listIncomingMessages?: Maybe<MessagesResponse>;
  listOutgoingMessages?: Maybe<MessagesResponse>;
  listPatientReservations?: Maybe<PrivateInAppReservationsResponse>;
  listSelfCalendarItems?: Maybe<PrivateCalendarItemsResponse>;
  listSelfReservations?: Maybe<PrivateInAppReservationsResponse>;
  listTicketsByStatus?: Maybe<TicketsResponse>;
  listTimelineConcerns?: Maybe<ConcernsResponse>;
};

export type QueryCreateCounselingJwtArgs = {
  destId: Scalars['String'];
  fromId?: InputMaybe<Scalars['String']>;
};

export type QueryCreateGetObjectPresignedUrlArgs = {
  key: Scalars['String'];
  objectType: Scalars['String'];
};

export type QueryCreateGetObjectPresignedUrlByMessageIdArgs = {
  messageId: Scalars['String'];
};

export type QueryCreatePaymentAccountOnboardingUrlArgs = {
  failureUrl: Scalars['String'];
  mode: Scalars['String'];
  successUrl: Scalars['String'];
};

export type QueryCreatePaymentSessionArgs = {
  cancelUrl: Scalars['String'];
  counselingComment?: InputMaybe<Scalars['String']>;
  counselingContent?: InputMaybe<Scalars['String']>;
  counselingMajorClassification: Scalars['String'];
  counselingMinorClassification?: InputMaybe<Scalars['String']>;
  counselorId: Scalars['String'];
  planId: Scalars['String'];
  startTimestamp: Scalars['Int'];
  successUrl: Scalars['String'];
};

export type QueryCreatePutObjectPresignedUrlArgs = {
  contentType?: InputMaybe<Scalars['String']>;
  counselingRoomId?: InputMaybe<Scalars['String']>;
  objectType: Scalars['String'];
};

export type QueryGetCounselingRoomArgs = {
  counselingRoomId: Scalars['String'];
};

export type QueryGetCounselorArgs = {
  counselorId: Scalars['String'];
};

export type QueryGetMessageArgs = {
  messageId: Scalars['String'];
};

export type QueryGetPlanArgs = {
  planId: Scalars['String'];
};

export type QueryGetProgressNoteArgs = {
  progressNoteId: Scalars['String'];
};

export type QueryGetProgressNoteByReservationIdArgs = {
  reservationId: Scalars['String'];
};

export type QueryGetReservationArgs = {
  reservationId: Scalars['String'];
};

export type QueryGetUserArgs = {
  userId: Scalars['String'];
};

export type QueryListActiveCounselorsArgs = {
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListCounselorAvailableTimesArgs = {
  counselorId: Scalars['String'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  startTimestamp: Scalars['Int'];
};

export type QueryListCounselorCalendarItemsArgs = {
  counselorId: Scalars['String'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  startTimestamp: Scalars['Int'];
};

export type QueryListCounselorPlansArgs = {
  counselorId: Scalars['String'];
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListCounselorReservationsArgs = {
  counselorId: Scalars['String'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  startTimestamp: Scalars['Int'];
};

export type QueryListIncomingMessagesArgs = {
  fromId?: InputMaybe<Scalars['String']>;
  lastSync: Scalars['Int'];
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListOutgoingMessagesArgs = {
  destId?: InputMaybe<Scalars['String']>;
  lastSync: Scalars['Int'];
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryListPatientReservationsArgs = {
  endTimestamp?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  startTimestamp: Scalars['Int'];
};

export type QueryListSelfCalendarItemsArgs = {
  endTimestamp?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  startTimestamp: Scalars['Int'];
};

export type QueryListSelfReservationsArgs = {
  endTimestamp?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  startTimestamp: Scalars['Int'];
};

export type QueryListTicketsByStatusArgs = {
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  registeredTimestampLowerLimit?: InputMaybe<Scalars['Int']>;
  registeredTimestampUpperLimit?: InputMaybe<Scalars['Int']>;
  ticketStatus: TicketStatus;
  ticketType?: InputMaybe<TicketType>;
};

export type QueryListTimelineConcernsArgs = {
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type Reservation = PrivateInAppReservation | PublicInAppReservation;

export enum ReservationStatus {
  Canceled = 'canceled',
  Finished = 'finished',
  Ongoing = 'ongoing',
  Proposed = 'proposed',
  Reserved = 'reserved',
  Tentative = 'tentative',
}

export type Service = {
  id: Scalars['ID'];
  registeredTimestamp: Scalars['Int'];
  serviceGroup: ServiceGroupName;
};

export enum ServiceGroupName {
  AvailableTimes = 'availableTimes',
  Reservations = 'reservations',
}

export enum ServiceStatus {
  Available = 'available',
  CancelFromCounselorChargeFailureRefundComplete = 'cancelFromCounselorChargeFailureRefundComplete',
  CancelFromCounselorRefundComplete = 'cancelFromCounselorRefundComplete',
  CancelFromCounselorRefundOngoing = 'cancelFromCounselorRefundOngoing',
  CancelFromCounselorRefundRequired = 'cancelFromCounselorRefundRequired',
  CancelFromCounselorRequested = 'cancelFromCounselorRequested',
  CancelFromOmamoriRefundComplete = 'cancelFromOmamoriRefundComplete',
  CancelFromOmamoriRefundOngoing = 'cancelFromOmamoriRefundOngoing',
  CancelFromOmamoriRefundRequired = 'cancelFromOmamoriRefundRequired',
  CancelFromOmamoriRequested = 'cancelFromOmamoriRequested',
  CancelFromPatientExaminationRequired = 'cancelFromPatientExaminationRequired',
  CancelFromPatientNoRefund = 'cancelFromPatientNoRefund',
  CancelFromPatientRefundComplete = 'cancelFromPatientRefundComplete',
  CancelFromPatientRefundOngoing = 'cancelFromPatientRefundOngoing',
  CancelFromPatientRefundRequired = 'cancelFromPatientRefundRequired',
  CancelFromPatientRequested = 'cancelFromPatientRequested',
  Error = 'error',
  Progress = 'progress',
  Reserved = 'reserved',
  ServiceFinish = 'serviceFinish',
  Tentative = 'tentative',
  TransferCanceled = 'transferCanceled',
  TransferComplete = 'transferComplete',
  TransferOngoing = 'transferOngoing',
  TransferReserved = 'transferReserved',
}

export enum ServiceType {
  Any = 'any',
  Text = 'text',
  TextNonImmediate = 'textNonImmediate',
  TextNonImmediateSubscription = 'textNonImmediateSubscription',
  TextSubscription = 'textSubscription',
  Video = 'video',
  VideoSubscription = 'videoSubscription',
  Voice = 'voice',
  VoiceSubscription = 'voiceSubscription',
}

export enum ServiceUnit {
  Frequency = 'frequency',
  Time = 'time',
}

export enum SettlementStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
  WaitingPayment = 'waitingPayment',
}

export type Subscription = {
  __typename?: 'Subscription';
  postedChat?: Maybe<Chat>;
};

export type SubscriptionPostedChatArgs = {
  destId: Scalars['String'];
};

export type TextMessage = Message & {
  __typename?: 'TextMessage';
  destId: Scalars['String'];
  fromId: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type Ticket = {
  assignees?: Maybe<Array<Maybe<Assignee>>>;
  assignerId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedTimestamp?: Maybe<Scalars['Int']>;
  deadlineTimestamp: Scalars['Int'];
  id: Scalars['ID'];
  registeredTimestamp: Scalars['Int'];
  requesterId: Scalars['String'];
  ticketGroup: TicketGroupName;
  ticketPriority: TicketPriority;
  ticketStatus: TicketStatus;
  ticketType: TicketType;
  updatedTimestamp?: Maybe<Scalars['Int']>;
};

export enum TicketGroupName {
  FunctionalRequirement = 'functionalRequirement',
  Troubleshooting = 'troubleshooting',
  UserSupport = 'userSupport',
  Workflow = 'workflow',
}

export enum TicketPriority {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum TicketStatus {
  Complete = 'complete',
  Doing = 'doing',
  ToDo = 'toDo',
}

export enum TicketType {
  UnconfirmedQualificationEvidenceTickets = 'unconfirmedQualificationEvidenceTickets',
}

export type TicketsResponse = {
  __typename?: 'TicketsResponse';
  nextToken?: Maybe<Scalars['String']>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
};

export enum TokenIssuanceDeniedReason {
  BeforeReservedTime = 'beforeReservedTime',
  InAiAssistantCoolingTime = 'inAiAssistantCoolingTime',
  NotReserved = 'notReserved',
}

export enum TransactionStatus {
  Complete = 'complete',
  Insufficient = 'insufficient',
  Ongoing = 'ongoing',
  Reserved = 'reserved',
}

export type UnconfirmedQualificationEvidence = BaseQualificationEvidence & {
  __typename?: 'UnconfirmedQualificationEvidence';
  evidenceConfirmationStatus: EvidenceConfirmationStatus;
  ticketId: Scalars['String'];
};

export type UnconfirmedQualificationEvidenceTicket = Ticket & {
  __typename?: 'UnconfirmedQualificationEvidenceTicket';
  assignees?: Maybe<Array<Maybe<Assignee>>>;
  assignerId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  completedTimestamp?: Maybe<Scalars['Int']>;
  counselorId: Scalars['String'];
  deadlineTimestamp: Scalars['Int'];
  id: Scalars['ID'];
  key: Scalars['String'];
  qualificationEvidenceConfirmationStatus?: Maybe<QualificationEvidenceConfirmationStatus>;
  qualificationName?: Maybe<Scalars['String']>;
  qualificationRegistrationNumber?: Maybe<Scalars['Int']>;
  registeredTimestamp: Scalars['Int'];
  requesterId: Scalars['String'];
  ticketGroup: TicketGroupName;
  ticketPriority: TicketPriority;
  ticketStatus: TicketStatus;
  ticketType: TicketType;
  updatedTimestamp?: Maybe<Scalars['Int']>;
};

export type UpdateCounselingRoomResponse = {
  __typename?: 'UpdateCounselingRoomResponse';
  counselingRoomId?: Maybe<Scalars['String']>;
};

export type User = {
  group: GroupName;
  id: Scalars['ID'];
  profileImage?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  version: Scalars['Int'];
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  nextToken?: Maybe<Scalars['String']>;
  users: Array<Maybe<User>>;
};

export type VideoMeetingInfo = {
  __typename?: 'VideoMeetingInfo';
  counselorAttendeeId: Scalars['String'];
  meetingId: Scalars['String'];
  patientAttendeeId?: Maybe<Scalars['String']>;
};

export type PublicPatientFragmentFragment = {
  __typename?: 'PublicPatient';
  id: string;
  group: GroupName;
  version: number;
  profileImage?: string;
  username: string;
  age?: number;
  gender?: string;
  firstName?: string;
  lastName?: string;
};

export type PrivatePatientFragmentFragment = {
  __typename?: 'PrivatePatient';
  id: string;
  group: GroupName;
  version: number;
  profileImage?: string;
  username: string;
  birthDate?: number;
  gender?: string;
  firstName?: string;
  lastName?: string;
  zipCode?: string;
  registeredTimestamp: number;
  updatedTimestamp: number;
  ban: boolean;
  aiAssistantId?: string;
};

export type PublicCounselorFragmentFragment = {
  __typename?: 'PublicCounselor';
  id: string;
  group: GroupName;
  version: number;
  profileImage?: string;
  username: string;
  generation?: number;
  gender?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  experts?: Array<string>;
  counselingRoomId?: string;
  description?: string;
  qualificationEvidences?: Array<
    | {
        __typename: 'ConfirmedCPPQualificationEvidence';
        qualificationName?: string;
        confirmedTimestamp?: number;
        evidenceConfirmationStatus: EvidenceConfirmationStatus;
      }
    | {
        __typename: 'ConfirmedCPQualificationEvidence';
        qualificationName?: string;
        evidenceConfirmationStatus: EvidenceConfirmationStatus;
        expiredTimestamp: number;
        confirmedTimestamp?: number;
      }
    | {
        __typename: 'UnconfirmedQualificationEvidence';
        evidenceConfirmationStatus: EvidenceConfirmationStatus;
        ticketId: string;
      }
  >;
};

export type PrivateCounselorFragmentFragment = {
  __typename?: 'PrivateCounselor';
  id: string;
  group: GroupName;
  version: number;
  profileImage?: string;
  username: string;
  birthDate?: number;
  generation?: number;
  gender?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  experts?: Array<string>;
  counselingRoomId?: string;
  description?: string;
  zipCode?: string;
  registeredTimestamp: number;
  updatedTimestamp: number;
  ban: boolean;
  stripeId: string;
  stripeCurrentDisabledReason?: string;
  stripeFutureDisabledReason?: string;
  qualificationEvidences?: Array<
    | {
        __typename: 'ConfirmedCPPQualificationEvidence';
        qualificationName?: string;
        confirmedTimestamp?: number;
        evidenceConfirmationStatus: EvidenceConfirmationStatus;
      }
    | {
        __typename: 'ConfirmedCPQualificationEvidence';
        qualificationName?: string;
        evidenceConfirmationStatus: EvidenceConfirmationStatus;
        expiredTimestamp: number;
        confirmedTimestamp?: number;
      }
    | {
        __typename: 'UnconfirmedQualificationEvidence';
        evidenceConfirmationStatus: EvidenceConfirmationStatus;
        ticketId: string;
      }
  >;
};

export type AiAssistantFragmentFragment = {
  __typename?: 'AiAssistant';
  id: string;
  group: GroupName;
  version: number;
  profileImage?: string;
  username: string;
  clientId?: string;
};

export type TextMessageFragmentFragment = {
  __typename?: 'TextMessage';
  id: string;
  fromId: string;
  destId: string;
  timestamp: number;
  text: string;
};

export type FileMessageFragmentFragment = {
  __typename?: 'FileMessage';
  id: string;
  fromId: string;
  destId: string;
  timestamp: number;
  key: string;
  originalName: string;
};

export type ImageMessageFragmentFragment = {
  __typename?: 'ImageMessage';
  id: string;
  fromId: string;
  destId: string;
  timestamp: number;
  key: string;
  thumbnailKey?: string;
  originalName: string;
};

export type CommentFragmentFragment = {
  __typename?: 'Comment';
  id: string;
  fromId: string;
  destId: string;
  concernId: string;
  timestamp: number;
  text: string;
};

export type ConcernFragmentFragment = {
  __typename?: 'Concern';
  id: string;
  fromId: string;
  destId: string;
  timestamp: number;
  text: string;
  genre: string;
  category: string;
};

export type PlanFragmentFragment = {
  __typename?: 'Plan';
  id: string;
  serviceType: ServiceType;
  serviceUnit: ServiceUnit;
  registeredTimestamp: number;
  updatedTimestamp?: number;
  minutes: number;
  price: number;
  stripeProductId: string;
  stripePriceId: string;
  name?: string;
  description?: string;
  adminType: PlanAdministrator;
  adminId: string;
  public: boolean;
  deleted: boolean;
};

export type PrivateInAppReservationFragmentFragment = {
  __typename?: 'PrivateInAppReservation';
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  serviceType: ServiceType;
  serviceUnit: ServiceUnit;
  registeredTimestamp: number;
  updatedTimestamp?: number;
  reservedTimestamp: number;
  counselorId: string;
  clientId: string;
  proposedUserType: ProposedUserType;
  reservationStatus: ReservationStatus;
  cancelReason?: CancelReason;
  cancelRequestedTimestamp?: number;
  expiredTimestamp?: number;
  planId: string;
  counselingMajorClassification?: string;
  counselingMinorClassification?: string;
  counselingContent?: string;
  counselingComment?: string;
  settlementId?: string;
  settlementRegisteredTimestamp?: number;
  settlementUpdatedTimestamp?: number;
  price?: number;
  omamoriPlatformFee?: number;
  settlementStatus?: SettlementStatus;
  checkoutSessionId?: string;
  paymentIntentId?: string;
  chargeId?: string;
  counselorStripeId?: string;
  url?: string;
  urlExpiredTimestamp?: number;
  transferPrice?: number;
  transferStatus?: TransactionStatus;
  transferStripeObjectId?: string;
  transferRequestedTimestamp?: number;
  transferCompleteTimestamp?: number;
  refundPrice?: number;
  refundStatus?: TransactionStatus;
  refundStripeObjectId?: string;
  refundRequestedTimestamp?: number;
  refundCompleteTimestamp?: number;
  counselorChargePrice?: number;
  counselorChargeStatus?: TransactionStatus;
  counselorChargeStripeObjectId?: string;
  counselorChargeRequestedTimestamp?: number;
  counselorChargeCompleteTimestamp?: number;
  counselorChargeInsufficientPrice?: number;
  counselorChargeUrl?: string;
  counselorChargeUrlExpiredTimestamp?: number;
  videoMeetingInfo?: {
    __typename?: 'VideoMeetingInfo';
    meetingId: string;
    counselorAttendeeId: string;
    patientAttendeeId?: string;
  };
};

export type PublicInAppReservationFragmentFragment = {
  __typename?: 'PublicInAppReservation';
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  registeredTimestamp: number;
  reservedTimestamp: number;
  counselorId: string;
  reservationStatus: ReservationStatus;
  expiredTimestamp?: number;
};

export type AvailableTimeFragmentFragment = {
  __typename?: 'AvailableTime';
  id: string;
  serviceGroup: ServiceGroupName;
  serviceType: ServiceType;
  registeredTimestamp: number;
  startTimestamp: number;
  endTimestamp: number;
  reserveDeadlineTimestamp: number;
  counselorId: string;
};

export type ProgressNoteFragmentFragment = {
  __typename?: 'ProgressNote';
  id: string;
  clientId: string;
  counselorId: string;
  reservationId: string;
  progressNoteStatus: ProgressNoteStatus;
  registeredTimestamp: number;
  savedTimestamp?: number;
  updatedTimestamp?: number;
  counselingMajorClassification: string;
  counselingMinorClassification: string;
  medicationStatus?: string;
  counselingPlan?: string;
  subjectiveData?: string;
  objectiveData?: string;
  assessment?: string;
  intervention?: string;
  other?: string;
  postscript?: string;
};

export type UnconfirmedQualificationEvidenceTicketFragmentFragment = {
  __typename?: 'UnconfirmedQualificationEvidenceTicket';
  id: string;
  key: string;
  qualificationName?: string;
  qualificationEvidenceConfirmationStatus?: QualificationEvidenceConfirmationStatus;
  qualificationRegistrationNumber?: number;
  registeredTimestamp: number;
  requesterId: string;
  ticketGroup: TicketGroupName;
  ticketPriority: TicketPriority;
  ticketStatus: TicketStatus;
  ticketType: TicketType;
  updatedTimestamp?: number;
  assignerId?: string;
  comment?: string;
  completedTimestamp?: number;
  counselorId: string;
  deadlineTimestamp: number;
  assignees?: Array<{
    __typename?: 'Assignee';
    assignedTimestamp: number;
    assigneeId: string;
  }>;
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?:
    | {
        __typename: 'AiAssistant';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        clientId?: string;
      }
    | {
        __typename: 'PrivateCounselor';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        birthDate?: number;
        generation?: number;
        gender?: string;
        firstName?: string;
        lastName?: string;
        displayName?: string;
        experts?: Array<string>;
        counselingRoomId?: string;
        description?: string;
        zipCode?: string;
        registeredTimestamp: number;
        updatedTimestamp: number;
        ban: boolean;
        stripeId: string;
        stripeCurrentDisabledReason?: string;
        stripeFutureDisabledReason?: string;
        qualificationEvidences?: Array<
          | {
              __typename: 'ConfirmedCPPQualificationEvidence';
              qualificationName?: string;
              confirmedTimestamp?: number;
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
            }
          | {
              __typename: 'ConfirmedCPQualificationEvidence';
              qualificationName?: string;
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
              expiredTimestamp: number;
              confirmedTimestamp?: number;
            }
          | {
              __typename: 'UnconfirmedQualificationEvidence';
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
              ticketId: string;
            }
        >;
      }
    | {
        __typename: 'PrivatePatient';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        birthDate?: number;
        gender?: string;
        firstName?: string;
        lastName?: string;
        zipCode?: string;
        registeredTimestamp: number;
        updatedTimestamp: number;
        ban: boolean;
        aiAssistantId?: string;
      }
    | {
        __typename: 'PublicCounselor';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        generation?: number;
        gender?: string;
        firstName?: string;
        lastName?: string;
        displayName?: string;
        experts?: Array<string>;
        counselingRoomId?: string;
        description?: string;
        qualificationEvidences?: Array<
          | {
              __typename: 'ConfirmedCPPQualificationEvidence';
              qualificationName?: string;
              confirmedTimestamp?: number;
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
            }
          | {
              __typename: 'ConfirmedCPQualificationEvidence';
              qualificationName?: string;
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
              expiredTimestamp: number;
              confirmedTimestamp?: number;
            }
          | {
              __typename: 'UnconfirmedQualificationEvidence';
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
              ticketId: string;
            }
        >;
      }
    | {
        __typename: 'PublicPatient';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        age?: number;
        gender?: string;
        firstName?: string;
        lastName?: string;
      };
};

export type GetCounselorQueryVariables = Exact<{
  counselorId: Scalars['String'];
}>;

export type GetCounselorQuery = {
  __typename?: 'Query';
  getCounselor?: {
    __typename: 'PublicCounselor';
    id: string;
    group: GroupName;
    version: number;
    profileImage?: string;
    username: string;
    generation?: number;
    gender?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    experts?: Array<string>;
    counselingRoomId?: string;
    description?: string;
    qualificationEvidences?: Array<
      | {
          __typename: 'ConfirmedCPPQualificationEvidence';
          qualificationName?: string;
          confirmedTimestamp?: number;
          evidenceConfirmationStatus: EvidenceConfirmationStatus;
        }
      | {
          __typename: 'ConfirmedCPQualificationEvidence';
          qualificationName?: string;
          evidenceConfirmationStatus: EvidenceConfirmationStatus;
          expiredTimestamp: number;
          confirmedTimestamp?: number;
        }
      | {
          __typename: 'UnconfirmedQualificationEvidence';
          evidenceConfirmationStatus: EvidenceConfirmationStatus;
          ticketId: string;
        }
    >;
  };
};

export type GetMessageQueryVariables = Exact<{
  messageId: Scalars['String'];
}>;

export type GetMessageQuery = {
  __typename?: 'Query';
  getMessage?:
    | {
        __typename: 'Comment';
        id: string;
        fromId: string;
        destId: string;
        concernId: string;
        timestamp: number;
        text: string;
      }
    | {
        __typename: 'Concern';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        text: string;
        genre: string;
        category: string;
      }
    | {
        __typename: 'FileMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        key: string;
        originalName: string;
      }
    | {
        __typename: 'ImageMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        key: string;
        thumbnailKey?: string;
        originalName: string;
      }
    | {
        __typename: 'TextMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        text: string;
      };
};

export type ListTimelineConcernsQueryVariables = Exact<{
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListTimelineConcernsQuery = {
  __typename?: 'Query';
  listTimelineConcerns?: {
    __typename?: 'ConcernsResponse';
    nextToken?: string;
    concerns: Array<{
      __typename: 'Concern';
      id: string;
      fromId: string;
      destId: string;
      timestamp: number;
      text: string;
      genre: string;
      category: string;
    }>;
  };
};

export type ListActiveCounselorsQueryVariables = Exact<{
  limit: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListActiveCounselorsQuery = {
  __typename?: 'Query';
  listActiveCounselors?: {
    __typename?: 'PublicCounselorsResponse';
    nextToken?: string;
    counselors: Array<{
      __typename: 'PublicCounselor';
      id: string;
      group: GroupName;
      version: number;
      profileImage?: string;
      username: string;
      generation?: number;
      gender?: string;
      firstName?: string;
      lastName?: string;
      displayName?: string;
      experts?: Array<string>;
      counselingRoomId?: string;
      description?: string;
      qualificationEvidences?: Array<
        | {
            __typename: 'ConfirmedCPPQualificationEvidence';
            qualificationName?: string;
            confirmedTimestamp?: number;
            evidenceConfirmationStatus: EvidenceConfirmationStatus;
          }
        | {
            __typename: 'ConfirmedCPQualificationEvidence';
            qualificationName?: string;
            evidenceConfirmationStatus: EvidenceConfirmationStatus;
            expiredTimestamp: number;
            confirmedTimestamp?: number;
          }
        | {
            __typename: 'UnconfirmedQualificationEvidence';
            evidenceConfirmationStatus: EvidenceConfirmationStatus;
            ticketId: string;
          }
      >;
    }>;
  };
};

export type CreatePutObjectPresignedUrlQueryVariables = Exact<{
  objectType: Scalars['String'];
  contentType?: InputMaybe<Scalars['String']>;
}>;

export type CreatePutObjectPresignedUrlQuery = {
  __typename?: 'Query';
  createPutObjectPresignedUrl?: {
    __typename?: 'PresignedUrlResponse';
    presignedUrl?: string;
  };
};

export type CreateGetObjectPresignedUrlQueryVariables = Exact<{
  objectType: Scalars['String'];
  key: Scalars['String'];
}>;

export type CreateGetObjectPresignedUrlQuery = {
  __typename?: 'Query';
  createGetObjectPresignedUrl?: {
    __typename?: 'PresignedUrlResponse';
    presignedUrl?: string;
  };
};

export type CreateGetObjectPresignedUrlByMessageIdQueryVariables = Exact<{
  messageId: Scalars['String'];
}>;

export type CreateGetObjectPresignedUrlByMessageIdQuery = {
  __typename?: 'Query';
  createGetObjectPresignedUrlByMessageId?: {
    __typename?: 'PresignedUrlResponse';
    presignedUrl?: string;
  };
};

export type GetPlanQueryVariables = Exact<{
  planId: Scalars['String'];
}>;

export type GetPlanQuery = {
  __typename?: 'Query';
  getPlan?: {
    __typename: 'Plan';
    id: string;
    serviceType: ServiceType;
    serviceUnit: ServiceUnit;
    registeredTimestamp: number;
    updatedTimestamp?: number;
    minutes: number;
    price: number;
    stripeProductId: string;
    stripePriceId: string;
    name?: string;
    description?: string;
    adminType: PlanAdministrator;
    adminId: string;
    public: boolean;
    deleted: boolean;
  };
};

export type ListCounselorPlansQueryVariables = Exact<{
  limit: Scalars['Int'];
  counselorId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListCounselorPlansQuery = {
  __typename?: 'Query';
  listCounselorPlans?: {
    __typename?: 'PlansResponse';
    nextToken?: string;
    plans?: Array<{
      __typename: 'Plan';
      id: string;
      serviceType: ServiceType;
      serviceUnit: ServiceUnit;
      registeredTimestamp: number;
      updatedTimestamp?: number;
      minutes: number;
      price: number;
      stripeProductId: string;
      stripePriceId: string;
      name?: string;
      description?: string;
      adminType: PlanAdministrator;
      adminId: string;
      public: boolean;
      deleted: boolean;
    }>;
  };
};

export type ListCounselorCalendarItemsQueryVariables = Exact<{
  limit: Scalars['Int'];
  counselorId: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListCounselorCalendarItemsQuery = {
  __typename?: 'Query';
  listCounselorCalendarItems?: {
    __typename?: 'PublicCalendarItemsResponse';
    nextToken?: string;
    calendarItems: Array<
      | {
          __typename: 'AvailableTime';
          id: string;
          serviceGroup: ServiceGroupName;
          serviceType: ServiceType;
          registeredTimestamp: number;
          startTimestamp: number;
          endTimestamp: number;
          reserveDeadlineTimestamp: number;
          counselorId: string;
        }
      | {
          __typename: 'PublicInAppReservation';
          id: string;
          startTimestamp: number;
          endTimestamp: number;
          registeredTimestamp: number;
          reservedTimestamp: number;
          counselorId: string;
          reservationStatus: ReservationStatus;
          expiredTimestamp?: number;
        }
    >;
  };
};

export type ListCounselorReservationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  counselorId: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListCounselorReservationsQuery = {
  __typename?: 'Query';
  listCounselorReservations?: {
    __typename?: 'PublicInAppReservationsResponse';
    nextToken?: string;
    reservations: Array<{
      __typename: 'PublicInAppReservation';
      id: string;
      startTimestamp: number;
      endTimestamp: number;
      registeredTimestamp: number;
      reservedTimestamp: number;
      counselorId: string;
      reservationStatus: ReservationStatus;
      expiredTimestamp?: number;
    }>;
  };
};

export type ListCounselorAvailableTimesQueryVariables = Exact<{
  limit: Scalars['Int'];
  counselorId: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListCounselorAvailableTimesQuery = {
  __typename?: 'Query';
  listCounselorAvailableTimes?: {
    __typename?: 'AvailableTimesResponse';
    nextToken?: string;
    availableTimes: Array<{
      __typename: 'AvailableTime';
      id: string;
      serviceGroup: ServiceGroupName;
      serviceType: ServiceType;
      registeredTimestamp: number;
      startTimestamp: number;
      endTimestamp: number;
      reserveDeadlineTimestamp: number;
      counselorId: string;
    }>;
  };
};

export type ListSelfCalendarItemsQueryVariables = Exact<{
  limit: Scalars['Int'];
  startTimestamp: Scalars['Int'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListSelfCalendarItemsQuery = {
  __typename?: 'Query';
  listSelfCalendarItems?: {
    __typename?: 'PrivateCalendarItemsResponse';
    nextToken?: string;
    calendarItems: Array<
      | {
          __typename: 'AvailableTime';
          id: string;
          serviceGroup: ServiceGroupName;
          serviceType: ServiceType;
          registeredTimestamp: number;
          startTimestamp: number;
          endTimestamp: number;
          reserveDeadlineTimestamp: number;
          counselorId: string;
        }
      | {
          __typename: 'PrivateInAppReservation';
          id: string;
          startTimestamp: number;
          endTimestamp: number;
          serviceType: ServiceType;
          serviceUnit: ServiceUnit;
          registeredTimestamp: number;
          updatedTimestamp?: number;
          reservedTimestamp: number;
          counselorId: string;
          clientId: string;
          proposedUserType: ProposedUserType;
          reservationStatus: ReservationStatus;
          cancelReason?: CancelReason;
          cancelRequestedTimestamp?: number;
          expiredTimestamp?: number;
          planId: string;
          counselingMajorClassification?: string;
          counselingMinorClassification?: string;
          counselingContent?: string;
          counselingComment?: string;
          settlementId?: string;
          settlementRegisteredTimestamp?: number;
          settlementUpdatedTimestamp?: number;
          price?: number;
          omamoriPlatformFee?: number;
          settlementStatus?: SettlementStatus;
          checkoutSessionId?: string;
          paymentIntentId?: string;
          chargeId?: string;
          counselorStripeId?: string;
          url?: string;
          urlExpiredTimestamp?: number;
          transferPrice?: number;
          transferStatus?: TransactionStatus;
          transferStripeObjectId?: string;
          transferRequestedTimestamp?: number;
          transferCompleteTimestamp?: number;
          refundPrice?: number;
          refundStatus?: TransactionStatus;
          refundStripeObjectId?: string;
          refundRequestedTimestamp?: number;
          refundCompleteTimestamp?: number;
          counselorChargePrice?: number;
          counselorChargeStatus?: TransactionStatus;
          counselorChargeStripeObjectId?: string;
          counselorChargeRequestedTimestamp?: number;
          counselorChargeCompleteTimestamp?: number;
          counselorChargeInsufficientPrice?: number;
          counselorChargeUrl?: string;
          counselorChargeUrlExpiredTimestamp?: number;
          videoMeetingInfo?: {
            __typename?: 'VideoMeetingInfo';
            meetingId: string;
            counselorAttendeeId: string;
            patientAttendeeId?: string;
          };
        }
    >;
  };
};

export type ListSelfReservationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  startTimestamp: Scalars['Int'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListSelfReservationsQuery = {
  __typename?: 'Query';
  listSelfReservations?: {
    __typename?: 'PrivateInAppReservationsResponse';
    nextToken?: string;
    reservations: Array<{
      __typename: 'PrivateInAppReservation';
      id: string;
      startTimestamp: number;
      endTimestamp: number;
      serviceType: ServiceType;
      serviceUnit: ServiceUnit;
      registeredTimestamp: number;
      updatedTimestamp?: number;
      reservedTimestamp: number;
      counselorId: string;
      clientId: string;
      proposedUserType: ProposedUserType;
      reservationStatus: ReservationStatus;
      cancelReason?: CancelReason;
      cancelRequestedTimestamp?: number;
      expiredTimestamp?: number;
      planId: string;
      counselingMajorClassification?: string;
      counselingMinorClassification?: string;
      counselingContent?: string;
      counselingComment?: string;
      settlementId?: string;
      settlementRegisteredTimestamp?: number;
      settlementUpdatedTimestamp?: number;
      price?: number;
      omamoriPlatformFee?: number;
      settlementStatus?: SettlementStatus;
      checkoutSessionId?: string;
      paymentIntentId?: string;
      chargeId?: string;
      counselorStripeId?: string;
      url?: string;
      urlExpiredTimestamp?: number;
      transferPrice?: number;
      transferStatus?: TransactionStatus;
      transferStripeObjectId?: string;
      transferRequestedTimestamp?: number;
      transferCompleteTimestamp?: number;
      refundPrice?: number;
      refundStatus?: TransactionStatus;
      refundStripeObjectId?: string;
      refundRequestedTimestamp?: number;
      refundCompleteTimestamp?: number;
      counselorChargePrice?: number;
      counselorChargeStatus?: TransactionStatus;
      counselorChargeStripeObjectId?: string;
      counselorChargeRequestedTimestamp?: number;
      counselorChargeCompleteTimestamp?: number;
      counselorChargeInsufficientPrice?: number;
      counselorChargeUrl?: string;
      counselorChargeUrlExpiredTimestamp?: number;
      videoMeetingInfo?: {
        __typename?: 'VideoMeetingInfo';
        meetingId: string;
        counselorAttendeeId: string;
        patientAttendeeId?: string;
      };
    }>;
  };
};

export type ListPatientReservationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  patientId: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListPatientReservationsQuery = {
  __typename?: 'Query';
  listPatientReservations?: {
    __typename?: 'PrivateInAppReservationsResponse';
    nextToken?: string;
    reservations: Array<{
      __typename: 'PrivateInAppReservation';
      id: string;
      startTimestamp: number;
      endTimestamp: number;
      serviceType: ServiceType;
      serviceUnit: ServiceUnit;
      registeredTimestamp: number;
      updatedTimestamp?: number;
      reservedTimestamp: number;
      counselorId: string;
      clientId: string;
      proposedUserType: ProposedUserType;
      reservationStatus: ReservationStatus;
      cancelReason?: CancelReason;
      cancelRequestedTimestamp?: number;
      expiredTimestamp?: number;
      planId: string;
      counselingMajorClassification?: string;
      counselingMinorClassification?: string;
      counselingContent?: string;
      counselingComment?: string;
      settlementId?: string;
      settlementRegisteredTimestamp?: number;
      settlementUpdatedTimestamp?: number;
      price?: number;
      omamoriPlatformFee?: number;
      settlementStatus?: SettlementStatus;
      checkoutSessionId?: string;
      paymentIntentId?: string;
      chargeId?: string;
      counselorStripeId?: string;
      url?: string;
      urlExpiredTimestamp?: number;
      transferPrice?: number;
      transferStatus?: TransactionStatus;
      transferStripeObjectId?: string;
      transferRequestedTimestamp?: number;
      transferCompleteTimestamp?: number;
      refundPrice?: number;
      refundStatus?: TransactionStatus;
      refundStripeObjectId?: string;
      refundRequestedTimestamp?: number;
      refundCompleteTimestamp?: number;
      counselorChargePrice?: number;
      counselorChargeStatus?: TransactionStatus;
      counselorChargeStripeObjectId?: string;
      counselorChargeRequestedTimestamp?: number;
      counselorChargeCompleteTimestamp?: number;
      counselorChargeInsufficientPrice?: number;
      counselorChargeUrl?: string;
      counselorChargeUrlExpiredTimestamp?: number;
      videoMeetingInfo?: {
        __typename?: 'VideoMeetingInfo';
        meetingId: string;
        counselorAttendeeId: string;
        patientAttendeeId?: string;
      };
    }>;
  };
};

export type CreateCounselingJwtQueryVariables = Exact<{
  destId: Scalars['String'];
  fromId?: InputMaybe<Scalars['String']>;
}>;

export type CreateCounselingJwtQuery = {
  __typename?: 'Query';
  createCounselingJwt?: {
    __typename?: 'CreateCounselingJwtResponse';
    token?: string;
  };
};

export type CreatePaymentAccountOnboardingUrlQueryVariables = Exact<{
  successUrl: Scalars['String'];
  failureUrl: Scalars['String'];
  mode: Scalars['String'];
}>;

export type CreatePaymentAccountOnboardingUrlQuery = {
  __typename?: 'Query';
  createPaymentAccountOnboardingUrl?: {
    __typename?: 'CreatePaymentAccountOnboardingUrlResponse';
    url?: string;
  };
};

export type CreatePaymentSessionQueryVariables = Exact<{
  counselorId: Scalars['String'];
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
  planId: Scalars['String'];
  startTimestamp: Scalars['Int'];
  counselingMajorClassification: Scalars['String'];
  counselingMinorClassification?: InputMaybe<Scalars['String']>;
  counselingContent?: InputMaybe<Scalars['String']>;
  counselingComment?: InputMaybe<Scalars['String']>;
}>;

export type CreatePaymentSessionQuery = {
  __typename?: 'Query';
  createPaymentSession?: {
    __typename?: 'CreatePaymentSessionResponse';
    url?: string;
    doubleCounseling?: DoubleCounseling;
  };
};

export type GetCounselingRoomQueryVariables = Exact<{
  counselingRoomId: Scalars['String'];
}>;

export type GetCounselingRoomQuery = {
  __typename?: 'Query';
  getCounselingRoom?: {
    __typename?: 'CounselingRoom';
    id: string;
    adminCounselor: string;
    name: string;
    plans?: Array<string>;
    description?: string;
    address?: string;
    phoneNumber?: string;
    profileImage?: string;
    stripeId?: string;
    createdTimestamp: number;
    updatedTimestamp?: number;
    active?: boolean;
  };
};

export type GetReservationQueryVariables = Exact<{
  reservationId: Scalars['String'];
}>;

export type GetReservationQuery = {
  __typename?: 'Query';
  getReservation?:
    | {
        __typename: 'PrivateInAppReservation';
        id: string;
        startTimestamp: number;
        endTimestamp: number;
        serviceType: ServiceType;
        serviceUnit: ServiceUnit;
        registeredTimestamp: number;
        updatedTimestamp?: number;
        reservedTimestamp: number;
        counselorId: string;
        clientId: string;
        proposedUserType: ProposedUserType;
        reservationStatus: ReservationStatus;
        cancelReason?: CancelReason;
        cancelRequestedTimestamp?: number;
        expiredTimestamp?: number;
        planId: string;
        counselingMajorClassification?: string;
        counselingMinorClassification?: string;
        counselingContent?: string;
        counselingComment?: string;
        settlementId?: string;
        settlementRegisteredTimestamp?: number;
        settlementUpdatedTimestamp?: number;
        price?: number;
        omamoriPlatformFee?: number;
        settlementStatus?: SettlementStatus;
        checkoutSessionId?: string;
        paymentIntentId?: string;
        chargeId?: string;
        counselorStripeId?: string;
        url?: string;
        urlExpiredTimestamp?: number;
        transferPrice?: number;
        transferStatus?: TransactionStatus;
        transferStripeObjectId?: string;
        transferRequestedTimestamp?: number;
        transferCompleteTimestamp?: number;
        refundPrice?: number;
        refundStatus?: TransactionStatus;
        refundStripeObjectId?: string;
        refundRequestedTimestamp?: number;
        refundCompleteTimestamp?: number;
        counselorChargePrice?: number;
        counselorChargeStatus?: TransactionStatus;
        counselorChargeStripeObjectId?: string;
        counselorChargeRequestedTimestamp?: number;
        counselorChargeCompleteTimestamp?: number;
        counselorChargeInsufficientPrice?: number;
        counselorChargeUrl?: string;
        counselorChargeUrlExpiredTimestamp?: number;
        videoMeetingInfo?: {
          __typename?: 'VideoMeetingInfo';
          meetingId: string;
          counselorAttendeeId: string;
          patientAttendeeId?: string;
        };
      }
    | {
        __typename: 'PublicInAppReservation';
        id: string;
        startTimestamp: number;
        endTimestamp: number;
        registeredTimestamp: number;
        reservedTimestamp: number;
        counselorId: string;
        reservationStatus: ReservationStatus;
        expiredTimestamp?: number;
      };
};

export type ListIncomingMessagesQueryVariables = Exact<{
  limit: Scalars['Int'];
  lastSync: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  fromId?: InputMaybe<Scalars['String']>;
}>;

export type ListIncomingMessagesQuery = {
  __typename?: 'Query';
  listIncomingMessages?: {
    __typename?: 'MessagesResponse';
    nextToken?: string;
    messages: Array<
      | {
          __typename: 'Comment';
          id: string;
          fromId: string;
          destId: string;
          concernId: string;
          timestamp: number;
          text: string;
        }
      | {
          __typename: 'Concern';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          text: string;
          genre: string;
          category: string;
        }
      | {
          __typename: 'FileMessage';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          key: string;
          originalName: string;
        }
      | {
          __typename: 'ImageMessage';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          key: string;
          thumbnailKey?: string;
          originalName: string;
        }
      | {
          __typename: 'TextMessage';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          text: string;
        }
    >;
  };
};

export type ListOutgoingMessagesQueryVariables = Exact<{
  limit: Scalars['Int'];
  lastSync: Scalars['Int'];
  nextToken?: InputMaybe<Scalars['String']>;
  destId?: InputMaybe<Scalars['String']>;
}>;

export type ListOutgoingMessagesQuery = {
  __typename?: 'Query';
  listOutgoingMessages?: {
    __typename?: 'MessagesResponse';
    nextToken?: string;
    messages: Array<
      | {
          __typename: 'Comment';
          id: string;
          fromId: string;
          destId: string;
          concernId: string;
          timestamp: number;
          text: string;
        }
      | {
          __typename: 'Concern';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          text: string;
          genre: string;
          category: string;
        }
      | {
          __typename: 'FileMessage';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          key: string;
          originalName: string;
        }
      | {
          __typename: 'ImageMessage';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          key: string;
          thumbnailKey?: string;
          originalName: string;
        }
      | {
          __typename: 'TextMessage';
          id: string;
          fromId: string;
          destId: string;
          timestamp: number;
          text: string;
        }
    >;
  };
};

export type GetProgressNoteQueryVariables = Exact<{
  progressNoteId: Scalars['String'];
}>;

export type GetProgressNoteQuery = {
  __typename?: 'Query';
  getProgressNote?: {
    __typename: 'ProgressNote';
    id: string;
    clientId: string;
    counselorId: string;
    reservationId: string;
    progressNoteStatus: ProgressNoteStatus;
    registeredTimestamp: number;
    savedTimestamp?: number;
    updatedTimestamp?: number;
    counselingMajorClassification: string;
    counselingMinorClassification: string;
    medicationStatus?: string;
    counselingPlan?: string;
    subjectiveData?: string;
    objectiveData?: string;
    assessment?: string;
    intervention?: string;
    other?: string;
    postscript?: string;
  };
};

export type GetProgressNoteByReservationIdQueryVariables = Exact<{
  reservationId: Scalars['String'];
}>;

export type GetProgressNoteByReservationIdQuery = {
  __typename?: 'Query';
  getProgressNoteByReservationId?: {
    __typename: 'ProgressNote';
    id: string;
    clientId: string;
    counselorId: string;
    reservationId: string;
    progressNoteStatus: ProgressNoteStatus;
    registeredTimestamp: number;
    savedTimestamp?: number;
    updatedTimestamp?: number;
    counselingMajorClassification: string;
    counselingMinorClassification: string;
    medicationStatus?: string;
    counselingPlan?: string;
    subjectiveData?: string;
    objectiveData?: string;
    assessment?: string;
    intervention?: string;
    other?: string;
    postscript?: string;
  };
};

export type ListTicketsByStatusQueryVariables = Exact<{
  limit: Scalars['Int'];
  ticketStatus: TicketStatus;
  registeredTimestampLowerLimit?: InputMaybe<Scalars['Int']>;
  registeredTimestampUpperLimit?: InputMaybe<Scalars['Int']>;
  ticketType?: InputMaybe<TicketType>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type ListTicketsByStatusQuery = {
  __typename?: 'Query';
  listTicketsByStatus?: {
    __typename?: 'TicketsResponse';
    tickets?: Array<{
      __typename?: 'UnconfirmedQualificationEvidenceTicket';
      id: string;
      assignerId?: string;
      comment?: string;
      completedTimestamp?: number;
      counselorId: string;
      deadlineTimestamp: number;
      key: string;
      qualificationEvidenceConfirmationStatus?: QualificationEvidenceConfirmationStatus;
      updatedTimestamp?: number;
      ticketType: TicketType;
      ticketStatus: TicketStatus;
      ticketPriority: TicketPriority;
      ticketGroup: TicketGroupName;
      requesterId: string;
      registeredTimestamp: number;
      qualificationRegistrationNumber?: number;
      qualificationName?: string;
      assignees?: Array<{
        __typename?: 'Assignee';
        assignedTimestamp: number;
        assigneeId: string;
      }>;
    }>;
  };
};

export type CreateUserMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['Int']>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?:
    | {
        __typename: 'PrivateCounselor';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        birthDate?: number;
        generation?: number;
        gender?: string;
        firstName?: string;
        lastName?: string;
        displayName?: string;
        experts?: Array<string>;
        counselingRoomId?: string;
        description?: string;
        zipCode?: string;
        registeredTimestamp: number;
        updatedTimestamp: number;
        ban: boolean;
        stripeId: string;
        stripeCurrentDisabledReason?: string;
        stripeFutureDisabledReason?: string;
        qualificationEvidences?: Array<
          | {
              __typename: 'ConfirmedCPPQualificationEvidence';
              qualificationName?: string;
              confirmedTimestamp?: number;
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
            }
          | {
              __typename: 'ConfirmedCPQualificationEvidence';
              qualificationName?: string;
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
              expiredTimestamp: number;
              confirmedTimestamp?: number;
            }
          | {
              __typename: 'UnconfirmedQualificationEvidence';
              evidenceConfirmationStatus: EvidenceConfirmationStatus;
              ticketId: string;
            }
        >;
      }
    | {
        __typename: 'PrivatePatient';
        id: string;
        group: GroupName;
        version: number;
        profileImage?: string;
        username: string;
        birthDate?: number;
        gender?: string;
        firstName?: string;
        lastName?: string;
        zipCode?: string;
        registeredTimestamp: number;
        updatedTimestamp: number;
        ban: boolean;
        aiAssistantId?: string;
      };
};

export type UpdatePatientMutationVariables = Exact<{
  gender?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  useAiAssistant?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePatientMutation = {
  __typename?: 'Mutation';
  updatePatient?: {
    __typename: 'PrivatePatient';
    id: string;
    group: GroupName;
    version: number;
    profileImage?: string;
    username: string;
    birthDate?: number;
    gender?: string;
    firstName?: string;
    lastName?: string;
    zipCode?: string;
    registeredTimestamp: number;
    updatedTimestamp: number;
    ban: boolean;
    aiAssistantId?: string;
  };
};

export type UpdateCounselorMutationVariables = Exact<{
  gender?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  experts?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  description?: InputMaybe<Scalars['String']>;
}>;

export type UpdateCounselorMutation = {
  __typename?: 'Mutation';
  updateCounselor?: {
    __typename: 'PrivateCounselor';
    id: string;
    group: GroupName;
    version: number;
    profileImage?: string;
    username: string;
    birthDate?: number;
    generation?: number;
    gender?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    experts?: Array<string>;
    counselingRoomId?: string;
    description?: string;
    zipCode?: string;
    registeredTimestamp: number;
    updatedTimestamp: number;
    ban: boolean;
    stripeId: string;
    stripeCurrentDisabledReason?: string;
    stripeFutureDisabledReason?: string;
    qualificationEvidences?: Array<
      | {
          __typename: 'ConfirmedCPPQualificationEvidence';
          qualificationName?: string;
          confirmedTimestamp?: number;
          evidenceConfirmationStatus: EvidenceConfirmationStatus;
        }
      | {
          __typename: 'ConfirmedCPQualificationEvidence';
          qualificationName?: string;
          evidenceConfirmationStatus: EvidenceConfirmationStatus;
          expiredTimestamp: number;
          confirmedTimestamp?: number;
        }
      | {
          __typename: 'UnconfirmedQualificationEvidence';
          evidenceConfirmationStatus: EvidenceConfirmationStatus;
          ticketId: string;
        }
    >;
  };
};

export type PostCommentMutationVariables = Exact<{
  destId: Scalars['String'];
  concernId: Scalars['String'];
  attachments: Scalars['String'];
}>;

export type PostCommentMutation = {
  __typename?: 'Mutation';
  postComment?: {
    __typename: 'Comment';
    id: string;
    fromId: string;
    destId: string;
    concernId: string;
    timestamp: number;
    text: string;
  };
};

export type PostConcernMutationVariables = Exact<{
  attachments: Scalars['String'];
}>;

export type PostConcernMutation = {
  __typename?: 'Mutation';
  postConcern?: Array<{
    __typename: 'Concern';
    id: string;
    fromId: string;
    destId: string;
    timestamp: number;
    text: string;
    genre: string;
    category: string;
  }>;
};

export type PostChatMutationVariables = Exact<{
  destId: Scalars['String'];
  fromId?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
  attachments: Scalars['String'];
  chatId?: InputMaybe<Scalars['String']>;
}>;

export type PostChatMutation = {
  __typename?: 'Mutation';
  postChat?:
    | {
        __typename: 'FileMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        key: string;
        originalName: string;
      }
    | {
        __typename: 'ImageMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        key: string;
        thumbnailKey?: string;
        originalName: string;
      }
    | {
        __typename: 'TextMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        text: string;
      };
};

export type CreatePlanMutationVariables = Exact<{
  planName: Scalars['String'];
  planDescription: Scalars['String'];
  serviceType: ServiceType;
  serviceUnit: ServiceUnit;
  minutes: Scalars['Int'];
  price: Scalars['Int'];
}>;

export type CreatePlanMutation = {
  __typename?: 'Mutation';
  createPlan?: {
    __typename: 'Plan';
    id: string;
    serviceType: ServiceType;
    serviceUnit: ServiceUnit;
    registeredTimestamp: number;
    updatedTimestamp?: number;
    minutes: number;
    price: number;
    stripeProductId: string;
    stripePriceId: string;
    name?: string;
    description?: string;
    adminType: PlanAdministrator;
    adminId: string;
    public: boolean;
    deleted: boolean;
  };
};

export type UpdatePlanMutationVariables = Exact<{
  planId: Scalars['String'];
  planName?: InputMaybe<Scalars['String']>;
  planDescription?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePlanMutation = {
  __typename?: 'Mutation';
  updatePlan?: {
    __typename: 'Plan';
    id: string;
    serviceType: ServiceType;
    serviceUnit: ServiceUnit;
    registeredTimestamp: number;
    updatedTimestamp?: number;
    minutes: number;
    price: number;
    stripeProductId: string;
    stripePriceId: string;
    name?: string;
    description?: string;
    adminType: PlanAdministrator;
    adminId: string;
    public: boolean;
    deleted: boolean;
  };
};

export type DeletePlanMutationVariables = Exact<{
  planId: Scalars['String'];
}>;

export type DeletePlanMutation = {
  __typename?: 'Mutation';
  deletePlan?: {
    __typename: 'Plan';
    id: string;
    serviceType: ServiceType;
    serviceUnit: ServiceUnit;
    registeredTimestamp: number;
    updatedTimestamp?: number;
    minutes: number;
    price: number;
    stripeProductId: string;
    stripePriceId: string;
    name?: string;
    description?: string;
    adminType: PlanAdministrator;
    adminId: string;
    public: boolean;
    deleted: boolean;
  };
};

export type CreateAvailableTimeMutationVariables = Exact<{
  startTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
  reserveDeadlineTimestamp?: InputMaybe<Scalars['Int']>;
  serviceType?: InputMaybe<ServiceType>;
}>;

export type CreateAvailableTimeMutation = {
  __typename?: 'Mutation';
  createAvailableTime?: {
    __typename: 'AvailableTime';
    id: string;
    serviceGroup: ServiceGroupName;
    serviceType: ServiceType;
    registeredTimestamp: number;
    startTimestamp: number;
    endTimestamp: number;
    reserveDeadlineTimestamp: number;
    counselorId: string;
  };
};

export type DeleteAvailableTimeMutationVariables = Exact<{
  availableTimeId: Scalars['String'];
}>;

export type DeleteAvailableTimeMutation = {
  __typename?: 'Mutation';
  deleteAvailableTime?: {
    __typename: 'AvailableTime';
    id: string;
    serviceGroup: ServiceGroupName;
    serviceType: ServiceType;
    registeredTimestamp: number;
    startTimestamp: number;
    endTimestamp: number;
    reserveDeadlineTimestamp: number;
    counselorId: string;
  };
};

export type CancelReservationMutationVariables = Exact<{
  reservationId: Scalars['String'];
}>;

export type CancelReservationMutation = {
  __typename?: 'Mutation';
  cancelReservation?: {
    __typename?: 'CancelReservationResponse';
    refundPrice?: number;
  };
};

export type UpdateReservationMutationVariables = Exact<{
  reservationId: Scalars['String'];
  serviceType?: InputMaybe<ServiceType>;
  startTimestamp?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateReservationMutation = {
  __typename?: 'Mutation';
  updateReservation?: {
    __typename: 'PrivateInAppReservation';
    id: string;
    startTimestamp: number;
    endTimestamp: number;
    serviceType: ServiceType;
    serviceUnit: ServiceUnit;
    registeredTimestamp: number;
    updatedTimestamp?: number;
    reservedTimestamp: number;
    counselorId: string;
    clientId: string;
    proposedUserType: ProposedUserType;
    reservationStatus: ReservationStatus;
    cancelReason?: CancelReason;
    cancelRequestedTimestamp?: number;
    expiredTimestamp?: number;
    planId: string;
    counselingMajorClassification?: string;
    counselingMinorClassification?: string;
    counselingContent?: string;
    counselingComment?: string;
    settlementId?: string;
    settlementRegisteredTimestamp?: number;
    settlementUpdatedTimestamp?: number;
    price?: number;
    omamoriPlatformFee?: number;
    settlementStatus?: SettlementStatus;
    checkoutSessionId?: string;
    paymentIntentId?: string;
    chargeId?: string;
    counselorStripeId?: string;
    url?: string;
    urlExpiredTimestamp?: number;
    transferPrice?: number;
    transferStatus?: TransactionStatus;
    transferStripeObjectId?: string;
    transferRequestedTimestamp?: number;
    transferCompleteTimestamp?: number;
    refundPrice?: number;
    refundStatus?: TransactionStatus;
    refundStripeObjectId?: string;
    refundRequestedTimestamp?: number;
    refundCompleteTimestamp?: number;
    counselorChargePrice?: number;
    counselorChargeStatus?: TransactionStatus;
    counselorChargeStripeObjectId?: string;
    counselorChargeRequestedTimestamp?: number;
    counselorChargeCompleteTimestamp?: number;
    counselorChargeInsufficientPrice?: number;
    counselorChargeUrl?: string;
    counselorChargeUrlExpiredTimestamp?: number;
    videoMeetingInfo?: {
      __typename?: 'VideoMeetingInfo';
      meetingId: string;
      counselorAttendeeId: string;
      patientAttendeeId?: string;
    };
  };
};

export type CreateCounselingRoomMutationVariables = Exact<{
  adminCounselor?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}>;

export type CreateCounselingRoomMutation = {
  __typename?: 'Mutation';
  createCounselingRoom?: {
    __typename?: 'CreateCounselingRoomResponse';
    counselingRoomId?: string;
  };
};

export type UpdateCounselingRoomMutationVariables = Exact<{
  counselingRoomId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateCounselingRoomMutation = {
  __typename?: 'Mutation';
  updateCounselingRoom?: {
    __typename?: 'UpdateCounselingRoomResponse';
    counselingRoomId?: string;
  };
};

export type CreateVideoMeetingMutationVariables = Exact<{
  reservationId: Scalars['String'];
  token: Scalars['String'];
}>;

export type CreateVideoMeetingMutation = {
  __typename?: 'Mutation';
  createVideoMeeting?: {
    __typename?: 'CreateVideoMeetingResponse';
    meeting?: string;
    attendee?: string;
  };
};

export type AttendVideoMeetingMutationVariables = Exact<{
  reservationId: Scalars['String'];
  token: Scalars['String'];
}>;

export type AttendVideoMeetingMutation = {
  __typename?: 'Mutation';
  attendVideoMeeting?: {
    __typename?: 'AttendVideoMeetingResponse';
    meeting?: string;
    attendee?: string;
  };
};

export type CreateProgressNoteMutationVariables = Exact<{
  reservationId: Scalars['String'];
  medicationStatus?: InputMaybe<Scalars['String']>;
  counselingPlan?: InputMaybe<Scalars['String']>;
  subjectiveData?: InputMaybe<Scalars['String']>;
  objectiveData?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
}>;

export type CreateProgressNoteMutation = {
  __typename?: 'Mutation';
  createProgressNote?: {
    __typename: 'ProgressNote';
    id: string;
    clientId: string;
    counselorId: string;
    reservationId: string;
    progressNoteStatus: ProgressNoteStatus;
    registeredTimestamp: number;
    savedTimestamp?: number;
    updatedTimestamp?: number;
    counselingMajorClassification: string;
    counselingMinorClassification: string;
    medicationStatus?: string;
    counselingPlan?: string;
    subjectiveData?: string;
    objectiveData?: string;
    assessment?: string;
    intervention?: string;
    other?: string;
    postscript?: string;
  };
};

export type UpdateProgressNoteMutationVariables = Exact<{
  progressNoteId: Scalars['String'];
  medicationStatus?: InputMaybe<Scalars['String']>;
  counselingPlan?: InputMaybe<Scalars['String']>;
  subjectiveData?: InputMaybe<Scalars['String']>;
  objectiveData?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
}>;

export type UpdateProgressNoteMutation = {
  __typename?: 'Mutation';
  updateProgressNote?: {
    __typename: 'ProgressNote';
    id: string;
    clientId: string;
    counselorId: string;
    reservationId: string;
    progressNoteStatus: ProgressNoteStatus;
    registeredTimestamp: number;
    savedTimestamp?: number;
    updatedTimestamp?: number;
    counselingMajorClassification: string;
    counselingMinorClassification: string;
    medicationStatus?: string;
    counselingPlan?: string;
    subjectiveData?: string;
    objectiveData?: string;
    assessment?: string;
    intervention?: string;
    other?: string;
    postscript?: string;
  };
};

export type FixProgressNoteMutationVariables = Exact<{
  progressNoteId: Scalars['String'];
  medicationStatus?: InputMaybe<Scalars['String']>;
  counselingPlan?: InputMaybe<Scalars['String']>;
  subjectiveData?: InputMaybe<Scalars['String']>;
  objectiveData?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  intervention?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
}>;

export type FixProgressNoteMutation = {
  __typename?: 'Mutation';
  fixProgressNote?: {
    __typename: 'ProgressNote';
    id: string;
    clientId: string;
    counselorId: string;
    reservationId: string;
    progressNoteStatus: ProgressNoteStatus;
    registeredTimestamp: number;
    savedTimestamp?: number;
    updatedTimestamp?: number;
    counselingMajorClassification: string;
    counselingMinorClassification: string;
    medicationStatus?: string;
    counselingPlan?: string;
    subjectiveData?: string;
    objectiveData?: string;
    assessment?: string;
    intervention?: string;
    other?: string;
    postscript?: string;
  };
};

export type AddPostscriptToProgressNoteMutationVariables = Exact<{
  progressNoteId: Scalars['String'];
  postscript: Scalars['String'];
}>;

export type AddPostscriptToProgressNoteMutation = {
  __typename?: 'Mutation';
  addPostscriptToProgressNote?: {
    __typename: 'ProgressNote';
    id: string;
    clientId: string;
    counselorId: string;
    reservationId: string;
    progressNoteStatus: ProgressNoteStatus;
    registeredTimestamp: number;
    savedTimestamp?: number;
    updatedTimestamp?: number;
    counselingMajorClassification: string;
    counselingMinorClassification: string;
    medicationStatus?: string;
    counselingPlan?: string;
    subjectiveData?: string;
    objectiveData?: string;
    assessment?: string;
    intervention?: string;
    other?: string;
    postscript?: string;
  };
};

export type CheckQualificationEvidenceMutationVariables = Exact<{
  counselorId: Scalars['String'];
  ticketId: Scalars['String'];
  confirm: Scalars['Boolean'];
  qualificationName?: InputMaybe<Scalars['String']>;
  qualificationRegistrationNumber?: InputMaybe<Scalars['Int']>;
  expiredTimestamp?: InputMaybe<Scalars['Int']>;
}>;

export type CheckQualificationEvidenceMutation = {
  __typename?: 'Mutation';
  checkQualificationEvidence?: {
    __typename?: 'CheckQualificationEvidenceResponse';
    ticketId?: string;
  };
};

export type PostedChatSubscriptionVariables = Exact<{
  destId: Scalars['String'];
}>;

export type PostedChatSubscription = {
  __typename?: 'Subscription';
  postedChat?:
    | {
        __typename: 'FileMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        key: string;
        originalName: string;
      }
    | {
        __typename: 'ImageMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        key: string;
        thumbnailKey?: string;
        originalName: string;
      }
    | {
        __typename: 'TextMessage';
        id: string;
        fromId: string;
        destId: string;
        timestamp: number;
        text: string;
      };
};

export const PublicPatientFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'publicPatientFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicPatient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'age' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicPatientFragmentFragment, unknown>;
export const PrivatePatientFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'privatePatientFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivatePatient' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aiAssistantId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivatePatientFragmentFragment, unknown>;
export const PublicCounselorFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'publicCounselorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicCounselor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualificationEvidences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UnconfirmedQualificationEvidence',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'evidenceConfirmationStatus',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ticketId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ConfirmedCPQualificationEvidence',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationName' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'evidenceConfirmationStatus',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiredTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedTimestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ConfirmedCPPQualificationEvidence',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'evidenceConfirmationStatus',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'experts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselingRoomId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicCounselorFragmentFragment, unknown>;
export const PrivateCounselorFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'privateCounselorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateCounselor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'generation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualificationEvidences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UnconfirmedQualificationEvidence',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'evidenceConfirmationStatus',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ticketId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ConfirmedCPQualificationEvidence',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationName' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'evidenceConfirmationStatus',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expiredTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedTimestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ConfirmedCPPQualificationEvidence',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmedTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'evidenceConfirmationStatus',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'experts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselingRoomId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ban' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeCurrentDisabledReason' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripeFutureDisabledReason' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateCounselorFragmentFragment, unknown>;
export const AiAssistantFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'aiAssistantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AiAssistant' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'group' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AiAssistantFragmentFragment, unknown>;
export const TextMessageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'textMessageFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TextMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TextMessageFragmentFragment, unknown>;
export const FileMessageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fileMessageFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FileMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileMessageFragmentFragment, unknown>;
export const ImageMessageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'imageMessageFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ImageMessage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageMessageFragmentFragment, unknown>;
export const CommentFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Comment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'concernId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentFragmentFragment, unknown>;
export const ConcernFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'concernFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Concern' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'destId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'genre' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConcernFragmentFragment, unknown>;
export const PlanFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'planFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Plan' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stripePriceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adminType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'adminId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlanFragmentFragment, unknown>;
export const PrivateInAppReservationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'privateInAppReservationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PrivateInAppReservation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reservedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'proposedUserType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reservationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelRequestedTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselingMajorClassification' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselingMinorClassification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'counselingContent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselingComment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoMeetingInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'meetingId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'counselorAttendeeId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'patientAttendeeId' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settlementRegisteredTimestamp' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settlementUpdatedTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omamoriPlatformFee' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'settlementStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'checkoutSessionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentIntentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chargeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselorStripeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urlExpiredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transferStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferStripeObjectId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferRequestedTimestamp' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferCompleteTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refundPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'refundStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundStripeObjectId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundRequestedTimestamp' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundCompleteTimestamp' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargePrice' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeStatus' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeStripeObjectId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeRequestedTimestamp' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeCompleteTimestamp' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeInsufficientPrice' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselorChargeUrlExpiredTimestamp' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PrivateInAppReservationFragmentFragment, unknown>;
export const PublicInAppReservationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'publicInAppReservationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicInAppReservation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTimestamp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'reservedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reservationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredTimestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicInAppReservationFragmentFragment, unknown>;
export const AvailableTimeFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'availableTimeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AvailableTime' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'serviceType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTimestamp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reserveDeadlineTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'counselorId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableTimeFragmentFragment, unknown>;
export const ProgressNoteFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'progressNoteFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProgressNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reservationId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressNoteStatus' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'savedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselingMajorClassification' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counselingMinorClassification' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'medicationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'counselingPlan' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subjectiveData' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectiveData' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervention' } },
          { kind: 'Field', name: { kind: 'Name', value: 'other' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postscript' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProgressNoteFragmentFragment, unknown>;
export const UnconfirmedQualificationEvidenceTicketFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'unconfirmedQualificationEvidenceTicketFragment',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UnconfirmedQualificationEvidenceTicket' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'qualificationName' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'qualificationEvidenceConfirmationStatus',
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualificationRegistrationNumber' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registeredTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'requesterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketPriority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'assigneeId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedTimestamp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'counselorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deadlineTimestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnconfirmedQualificationEvidenceTicketFragmentFragment,
  unknown
>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PublicPatient' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'publicPatientFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivatePatient' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'privatePatientFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PublicCounselor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'publicCounselorFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivateCounselor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'privateCounselorFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AiAssistant' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'aiAssistantFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PublicPatientFragmentFragmentDoc.definitions,
    ...PrivatePatientFragmentFragmentDoc.definitions,
    ...PublicCounselorFragmentFragmentDoc.definitions,
    ...PrivateCounselorFragmentFragmentDoc.definitions,
    ...AiAssistantFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetCounselorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCounselor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCounselor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'publicCounselorFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PublicCounselorFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCounselorQuery, GetCounselorQueryVariables>;
export const GetMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TextMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'textMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FileMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fileMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'imageMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Concern' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'concernFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Comment' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'commentFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextMessageFragmentFragmentDoc.definitions,
    ...FileMessageFragmentFragmentDoc.definitions,
    ...ImageMessageFragmentFragmentDoc.definitions,
    ...ConcernFragmentFragmentDoc.definitions,
    ...CommentFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetMessageQuery, GetMessageQueryVariables>;
export const ListTimelineConcernsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listTimelineConcerns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listTimelineConcerns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'concerns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Concern' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'concernFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...ConcernFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListTimelineConcernsQuery,
  ListTimelineConcernsQueryVariables
>;
export const ListActiveCounselorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listActiveCounselors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listActiveCounselors' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'counselors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PublicCounselor' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'publicCounselorFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PublicCounselorFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListActiveCounselorsQuery,
  ListActiveCounselorsQueryVariables
>;
export const CreatePutObjectPresignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'createPutObjectPresignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contentType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPutObjectPresignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'contentType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presignedUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePutObjectPresignedUrlQuery,
  CreatePutObjectPresignedUrlQueryVariables
>;
export const CreateGetObjectPresignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'createGetObjectPresignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGetObjectPresignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'key' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presignedUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGetObjectPresignedUrlQuery,
  CreateGetObjectPresignedUrlQueryVariables
>;
export const CreateGetObjectPresignedUrlByMessageIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'createGetObjectPresignedUrlByMessageId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'messageId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createGetObjectPresignedUrlByMessageId',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'messageId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'messageId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presignedUrl' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGetObjectPresignedUrlByMessageIdQuery,
  CreateGetObjectPresignedUrlByMessageIdQueryVariables
>;
export const GetPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Plan' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'planFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlanFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPlanQuery, GetPlanQueryVariables>;
export const ListCounselorPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listCounselorPlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listCounselorPlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Plan' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'planFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PlanFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListCounselorPlansQuery,
  ListCounselorPlansQueryVariables
>;
export const ListCounselorCalendarItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listCounselorCalendarItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listCounselorCalendarItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PublicInAppReservation',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'publicInAppReservationFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AvailableTime' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'availableTimeFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PublicInAppReservationFragmentFragmentDoc.definitions,
    ...AvailableTimeFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListCounselorCalendarItemsQuery,
  ListCounselorCalendarItemsQueryVariables
>;
export const ListCounselorReservationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listCounselorReservations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listCounselorReservations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PublicInAppReservation',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'publicInAppReservationFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PublicInAppReservationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListCounselorReservationsQuery,
  ListCounselorReservationsQueryVariables
>;
export const ListCounselorAvailableTimesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listCounselorAvailableTimes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listCounselorAvailableTimes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AvailableTime' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'availableTimeFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...AvailableTimeFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListCounselorAvailableTimesQuery,
  ListCounselorAvailableTimesQueryVariables
>;
export const ListSelfCalendarItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listSelfCalendarItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listSelfCalendarItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PrivateInAppReservation',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'privateInAppReservationFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AvailableTime' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'availableTimeFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PrivateInAppReservationFragmentFragmentDoc.definitions,
    ...AvailableTimeFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListSelfCalendarItemsQuery,
  ListSelfCalendarItemsQueryVariables
>;
export const ListSelfReservationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listSelfReservations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listSelfReservations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PrivateInAppReservation',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'privateInAppReservationFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PrivateInAppReservationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListSelfReservationsQuery,
  ListSelfReservationsQueryVariables
>;
export const ListPatientReservationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listPatientReservations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'patientId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listPatientReservations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'patientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'PrivateInAppReservation',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'privateInAppReservationFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...PrivateInAppReservationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListPatientReservationsQuery,
  ListPatientReservationsQueryVariables
>;
export const CreateCounselingJwtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'createCounselingJwt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'destId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCounselingJwt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCounselingJwtQuery,
  CreateCounselingJwtQueryVariables
>;
export const CreatePaymentAccountOnboardingUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'createPaymentAccountOnboardingUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'successUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'failureUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mode' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPaymentAccountOnboardingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'successUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'successUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'failureUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'failureUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePaymentAccountOnboardingUrlQuery,
  CreatePaymentAccountOnboardingUrlQueryVariables
>;
export const CreatePaymentSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'createPaymentSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'successUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingMajorClassification' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingMinorClassification' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingContent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingComment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPaymentSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'successUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'successUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingMajorClassification' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'counselingMajorClassification',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingMinorClassification' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'counselingMinorClassification',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingContent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingContent' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingComment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingComment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'doubleCounseling' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePaymentSessionQuery,
  CreatePaymentSessionQueryVariables
>;
export const GetCounselingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCounselingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCounselingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingRoomId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingRoomId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adminCounselor' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plans' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdTimestamp' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCounselingRoomQuery,
  GetCounselingRoomQueryVariables
>;
export const GetReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivateInAppReservation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'privateInAppReservationFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PublicInAppReservation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'publicInAppReservationFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PrivateInAppReservationFragmentFragmentDoc.definitions,
    ...PublicInAppReservationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetReservationQuery, GetReservationQueryVariables>;
export const ListIncomingMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listIncomingMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastSync' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listIncomingMessages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastSync' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastSync' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TextMessage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'textMessageFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FileMessage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fileMessageFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ImageMessage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'imageMessageFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Concern' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'concernFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Comment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'commentFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...TextMessageFragmentFragmentDoc.definitions,
    ...FileMessageFragmentFragmentDoc.definitions,
    ...ImageMessageFragmentFragmentDoc.definitions,
    ...ConcernFragmentFragmentDoc.definitions,
    ...CommentFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListIncomingMessagesQuery,
  ListIncomingMessagesQueryVariables
>;
export const ListOutgoingMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listOutgoingMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastSync' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'destId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listOutgoingMessages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastSync' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastSync' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TextMessage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'textMessageFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FileMessage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fileMessageFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ImageMessage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'imageMessageFragment',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Concern' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'concernFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Comment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'commentFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
    ...TextMessageFragmentFragmentDoc.definitions,
    ...FileMessageFragmentFragmentDoc.definitions,
    ...ImageMessageFragmentFragmentDoc.definitions,
    ...ConcernFragmentFragmentDoc.definitions,
    ...CommentFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ListOutgoingMessagesQuery,
  ListOutgoingMessagesQueryVariables
>;
export const GetProgressNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProgressNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'progressNoteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProgressNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'progressNoteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'progressNoteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ProgressNote' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'progressNoteFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProgressNoteFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetProgressNoteQuery,
  GetProgressNoteQueryVariables
>;
export const GetProgressNoteByReservationIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProgressNoteByReservationId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProgressNoteByReservationId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ProgressNote' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'progressNoteFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProgressNoteFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetProgressNoteByReservationIdQuery,
  GetProgressNoteByReservationIdQueryVariables
>;
export const ListTicketsByStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listTicketsByStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ticketStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TicketStatus' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'registeredTimestampLowerLimit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'registeredTimestampUpperLimit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ticketType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TicketType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextToken' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listTicketsByStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ticketStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ticketStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registeredTimestampLowerLimit' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'registeredTimestampLowerLimit',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registeredTimestampUpperLimit' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'registeredTimestampUpperLimit',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ticketType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ticketType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tickets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'UnconfirmedQualificationEvidenceTicket',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignerId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'completedTimestamp',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'counselorId' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deadlineTimestamp',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value:
                                  'qualificationEvidenceConfirmationStatus',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedTimestamp' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ticketType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ticketStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ticketPriority' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ticketGroup' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requesterId' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'registeredTimestamp',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'qualificationRegistrationNumber',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'qualificationName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignees' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignedTimestamp',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'assigneeId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListTicketsByStatusQuery,
  ListTicketsByStatusQueryVariables
>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileImage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'birthDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileImage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileImage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zipCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'birthDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'birthDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivatePatient' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'privatePatientFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivateCounselor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'privateCounselorFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PrivatePatientFragmentFragmentDoc.definitions,
    ...PrivateCounselorFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdatePatientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePatient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileImage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'useAiAssistant' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePatient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileImage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileImage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zipCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useAiAssistant' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'useAiAssistant' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivatePatient' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'privatePatientFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PrivatePatientFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;
export const UpdateCounselorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCounselor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'zipCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileImage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'experts' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCounselor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zipCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zipCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileImage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileImage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'experts' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'experts' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivateCounselor' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'privateCounselorFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PrivateCounselorFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateCounselorMutation,
  UpdateCounselorMutationVariables
>;
export const PostCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'postComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'destId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'concernId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attachments' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'concernId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'concernId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachments' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'attachments' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Comment' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'commentFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CommentFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PostCommentMutation, PostCommentMutationVariables>;
export const PostConcernDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'postConcern' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attachments' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postConcern' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachments' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'attachments' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Concern' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'concernFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ConcernFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PostConcernMutation, PostConcernMutationVariables>;
export const PostChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'postChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'destId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'attachments' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attachments' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'attachments' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TextMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'textMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FileMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fileMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'imageMessageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextMessageFragmentFragmentDoc.definitions,
    ...FileMessageFragmentFragmentDoc.definitions,
    ...ImageMessageFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PostChatMutation, PostChatMutationVariables>;
export const CreatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planDescription' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceUnit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceUnit' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minutes' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'price' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planDescription' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planDescription' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceUnit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceUnit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minutes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minutes' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'price' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'price' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Plan' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'planFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlanFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planDescription' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planDescription' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planDescription' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Plan' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'planFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlanFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const DeletePlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'planId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'planId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Plan' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'planFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlanFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeletePlanMutation, DeletePlanMutationVariables>;
export const CreateAvailableTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAvailableTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endTimestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reserveDeadlineTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAvailableTime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reserveDeadlineTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reserveDeadlineTimestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AvailableTime' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'availableTimeFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AvailableTimeFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateAvailableTimeMutation,
  CreateAvailableTimeMutationVariables
>;
export const DeleteAvailableTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAvailableTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTimeId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAvailableTime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'availableTimeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'availableTimeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AvailableTime' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'availableTimeFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AvailableTimeFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  DeleteAvailableTimeMutation,
  DeleteAvailableTimeMutationVariables
>;
export const CancelReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'refundPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelReservationMutation,
  CancelReservationMutationVariables
>;
export const UpdateReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startTimestamp' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PrivateInAppReservation' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'privateInAppReservationFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PrivateInAppReservationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateReservationMutation,
  UpdateReservationMutationVariables
>;
export const CreateCounselingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCounselingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adminCounselor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCounselingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adminCounselor' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'adminCounselor' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'counselingRoomId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCounselingRoomMutation,
  CreateCounselingRoomMutationVariables
>;
export const UpdateCounselingRoomDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCounselingRoom' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingRoomId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'address' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'profileImage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCounselingRoom' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingRoomId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingRoomId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'address' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phoneNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phoneNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileImage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'profileImage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'active' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'counselingRoomId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCounselingRoomMutation,
  UpdateCounselingRoomMutationVariables
>;
export const CreateVideoMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createVideoMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVideoMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'meeting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attendee' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateVideoMeetingMutation,
  CreateVideoMeetingMutationVariables
>;
export const AttendVideoMeetingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'attendVideoMeeting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendVideoMeeting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'meeting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'attendee' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AttendVideoMeetingMutation,
  AttendVideoMeetingMutationVariables
>;
export const CreateProgressNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProgressNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reservationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'medicationStatus' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingPlan' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subjectiveData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectiveData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'intervention' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'other' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProgressNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reservationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'medicationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'medicationStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingPlan' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingPlan' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectiveData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subjectiveData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectiveData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectiveData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assessment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assessment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'intervention' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'intervention' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'other' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'other' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ProgressNote' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'progressNoteFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProgressNoteFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateProgressNoteMutation,
  CreateProgressNoteMutationVariables
>;
export const UpdateProgressNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProgressNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'progressNoteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'medicationStatus' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingPlan' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subjectiveData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectiveData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'intervention' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'other' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProgressNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'progressNoteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'progressNoteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'medicationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'medicationStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingPlan' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingPlan' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectiveData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subjectiveData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectiveData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectiveData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assessment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assessment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'intervention' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'intervention' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'other' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'other' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ProgressNote' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'progressNoteFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProgressNoteFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateProgressNoteMutation,
  UpdateProgressNoteMutationVariables
>;
export const FixProgressNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'fixProgressNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'progressNoteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'medicationStatus' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselingPlan' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subjectiveData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objectiveData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assessment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'intervention' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'other' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fixProgressNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'progressNoteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'progressNoteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'medicationStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'medicationStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselingPlan' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselingPlan' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subjectiveData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subjectiveData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectiveData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objectiveData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assessment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assessment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'intervention' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'intervention' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'other' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'other' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ProgressNote' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'progressNoteFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProgressNoteFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  FixProgressNoteMutation,
  FixProgressNoteMutationVariables
>;
export const AddPostscriptToProgressNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addPostscriptToProgressNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'progressNoteId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postscript' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPostscriptToProgressNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'progressNoteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'progressNoteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postscript' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'postscript' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ProgressNote' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'progressNoteFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProgressNoteFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AddPostscriptToProgressNoteMutation,
  AddPostscriptToProgressNoteMutationVariables
>;
export const CheckQualificationEvidenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkQualificationEvidence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'counselorId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ticketId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'confirm' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'qualificationName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'qualificationRegistrationNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expiredTimestamp' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkQualificationEvidence' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'counselorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'counselorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ticketId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ticketId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'confirm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'confirm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qualificationName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'qualificationName' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'qualificationRegistrationNumber',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'qualificationRegistrationNumber',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expiredTimestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'expiredTimestamp' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckQualificationEvidenceMutation,
  CheckQualificationEvidenceMutationVariables
>;
export const PostedChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'postedChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'destId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postedChat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'TextMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'textMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FileMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fileMessageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageMessage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'imageMessageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextMessageFragmentFragmentDoc.definitions,
    ...FileMessageFragmentFragmentDoc.definitions,
    ...ImageMessageFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  PostedChatSubscription,
  PostedChatSubscriptionVariables
>;
