import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

const RenderLink = (to: string) =>
  React.useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link
          to={to}
          ref={ref}
          {...itemProps}
          style={{ textDecoration: 'none', color: 'inherit' }}
        />
      )),
    [to]
  );

export default RenderLink;
