import React, { useState } from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useSnackbar } from 'components/SnackbarProvider';
import logger from 'lib/logger';
import { useTempState } from 'controllers';
import useBooleanState from 'hooks/useBooleanState';
import Loading from 'layouts/Loading';

import AuthService from '../services/auth-service';
import { FeatureFlagService } from '../services/feature-flag-service';

const useStyle = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '80vw',
    wordBreak: 'break-word',
    margin: 'auto',
  },
}));

/**
 * API を叩くテストページ
 * リリース時には消す
 */
const Test: React.FC = () => {
  document.title = 'テスト';

  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [res, setRes] = useState<string>();
  const [err, setErr] = useState<string>();
  const [loading, setLoading, setNotLoading] = useBooleanState(false);

  const [input, setInput] = useTempState('test/input');

  const reset = () => Promise.all([setRes(''), setErr('')]);
  const handleClick = async () => {
    await reset();

    try {
      setLoading();

      // ここでテスト
      enqueueSnackbar({ message: 'test', variant: 'info' });
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      logger.log(cognitoUser);
      logger.log('get config specific version');
      const service = await FeatureFlagService.new();
      const featureName = 'progressNote';
      const config = await service.getFeatureFlag({ featureName });
      logger.log(config);
    } catch (err) {
      logger.error(err);
      setErr(JSON.stringify(err));
    } finally {
      setNotLoading();
    }
    return;
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    setInput(inputText);
  };

  return (
    <Box className={classes.root}>
      <Loading open={loading} text="API Testing" />
      <TextField
        multiline={true}
        minRows={4}
        placeholder="記入してください"
        variant="outlined"
        value={input}
        onChange={handleChangeInput}
        sx={{ m: 1, width: '95%' }}
      />
      <Button onClick={handleClick} variant="contained" color="primary">
        Call API
      </Button>
      {res && (
        <Box>
          <Typography>Response:</Typography>
          <Typography>{res}</Typography>
        </Box>
      )}
      {err && (
        <Box>
          <Typography>Error:</Typography>
          <Typography>{err}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Test;
