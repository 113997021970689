import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import FlexContainer from './FlexContainer';

interface FullScreenProps {
  title: string;
}

const FullScreenBase: React.FC<FullScreenProps> = ({ title, ...props }) => {
  document.title = title;
  const [height, setHeight] = useState<number>();

  const setFillHeight = () => setHeight(window.innerHeight);
  window.addEventListener('resize', setFillHeight);
  useEffect(setFillHeight, []);

  return <FlexContainer {...props} sx={{ height: height ?? '100vh' }} />;
};

const FullScreen = styled(FullScreenBase)`
  & > * {
    width: 100%;
  }
`;

export default FullScreen;
