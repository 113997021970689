type EncryptedText = string;

export interface UserModel {
  id: string;
  user: EncryptedText;
}

export interface MessageModel {
  __typename:
    | 'TextMessage'
    | 'FileMessage'
    | 'ImageMessage'
    | 'Concern'
    | 'Comment';
  id: string;
  fromId: string;
  destId: string;
  timestamp: number;
  text: EncryptedText;
}
export interface ReservationModel {
  id: string;
  timestamp: number;
  data: EncryptedText;
}
export interface AppConfigModel {
  acceptedTermsOfService: boolean;
  skipWelcome: boolean;
}
export interface CounselingJwt {
  expire: number;
  token: string;
}

export const profileKey = 'profile_v1';
export interface ProfileModel {
  key: typeof profileKey;
  id: string;
  cognitoUser: EncryptedText;
  privateUser?: EncryptedText;
}

export interface RoomModel {
  id: string;
  lastMessage?: MessageModel;
  reservation?: ReservationModel;
  counselingJwt?: CounselingJwt;
}

export interface NotificationModel {
  id: string;
  __typename: 'News' | 'Push';
  target: string;
  timestamp: number;
  title: string;
  md: string;
}

export interface TempModel {
  key: string;
  data: string;
}

export interface FeatureFlagModel {
  name: string;
  properties: FeatureFlagBody;
  storedTimestamp: number;
}

export interface FeatureFlagBody {
  [key: string]: unknown;
  enable: boolean;
}
