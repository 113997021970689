import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import GraphQlProvider from 'components/GraphQlProvider';
import SWUpdateDialog from 'components/SWUpdateDialog';
import SnackbarProvider from 'components/SnackbarProvider';
import { beforeInstallPromptEventListener } from 'lib/installPromptEvent';

import App from 'App';
import OmamoriThemeProvider from 'components/OmamoriThemeProvider';
import { register } from 'serviceWorkerRegistration';

import {
  MeetingProvider,
  lightTheme,
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';

const Root = () => (
  <React.StrictMode>
    <div className="SW-update-dialog" />
    <Router>
      <Suspense fallback={<div>loading</div>}>
        <OmamoriThemeProvider>
          <GraphQlProvider>
            <SnackbarProvider max={3}>
              <ThemeProvider theme={lightTheme}>
                <MeetingProvider>
                  <App />
                </MeetingProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </GraphQlProvider>
        </OmamoriThemeProvider>
      </Suspense>
    </Router>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));

window.addEventListener(
  'beforeinstallprompt',
  beforeInstallPromptEventListener
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      ReactDOM.render(
        <SWUpdateDialog registration={registration} />,
        document.querySelector('.SW-update-dialog')
      );
    }
  },
});
