import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  registration: ServiceWorkerRegistration;
}

const SWUpdateDialog: React.FC<Props> = ({ registration }) => {
  const [open, setOpen] = React.useState(true);

  const handleUpdate = () => {
    setOpen(false);
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  };

  return (
    <Dialog open={open} keepMounted={true} disableEscapeKeyDown={true}>
      <DialogTitle>更新</DialogTitle>
      <DialogContent>
        <DialogContentText>新しいバージョンを利用できます</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SWUpdateDialog;
