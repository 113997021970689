import React, { useEffect, useState } from 'react';

import { Message } from '@libs/share/graphql-interfaces/typed-document-node';
import { putReservation, useProfile } from 'db';
import logger from 'lib/logger';
import {
  getAllUserIds,
  refreshUser,
  subscribeMessage,
  syncAllMessages,
  getReservations,
} from 'controllers';
import { isMessage } from '@web/graphql/discriminator';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { ApolloProvider } from '@apollo/client';
import AuthService from '@web/services/auth-service';

const GraphQlProvider: React.FC = (props) => {
  /* Custom hooks and initialization */
  const profile = useProfile();
  const updatedUserIds = new Set<string>();

  const syncMessages = async () => {
    const newMessages = await syncAllMessages();
    if (isMessage(newMessages)) syncUsers(newMessages);
  };
  const startSubscribe = async (id: string, timeout = 100) => {
    const subscription = subscribeMessage(id, syncUser);
    subscription.catch(() => {
      setTimeout(() => startSubscribe(id, timeout * 2), timeout);
    });
  };

  const updateUser = async (id: string) => {
    if (!updatedUserIds.has(id)) {
      updatedUserIds.add(id);
      await refreshUser(id);
    }
  };

  const syncUser = async (message: Message) => {
    try {
      updateUser(message.destId);
      updateUser(message.fromId);
    } catch (error) {
      logger.error(error);
    }
  };

  const syncUsers = async (messages: Message[]) => messages.forEach(syncUser);

  const syncCalendarItems = async () => {
    const reservations = await getReservations();
    reservations?.forEach((reservation) => {
      putReservation(reservation);
      updateUser(reservation.counselorId);
      updateUser(reservation.clientId);
    });
  };

  useEffect(() => {
    if (!profile?.id) return;

    try {
      getAllUserIds().then((ids) => ids.map(updateUser));
      syncMessages();
      syncCalendarItems();
      startSubscribe(profile.id);
    } catch (error) {
      logger.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  /* Custom hooks and initialization */
  // Create a new hook without using GraphqlService.getApolloClient for easy migration from the old API calls
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);

  useEffect(() => {
    const initializeApolloClient = async () => {
      const link = await AuthService.getApolloLinkForAppSync();

      const newApolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
      });

      setApolloClient(newApolloClient);
    };

    initializeApolloClient();
  }, []);

  return !apolloClient ? (
    <>{props.children}</>
  ) : (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};

export default GraphQlProvider;
