import { PaletteColorOptions } from '@mui/material';
import rootPackage from '../../../package.json';

const targetGroup = process.env.REACT_APP_TARGET ?? 'patient';
export const isProduction = process.env.REACT_APP_STAGE === 'production';
export const targetIsPublicPatient = targetGroup === 'patient';
export const group = targetIsPublicPatient ? 'patients' : 'counselors';
export const mainColorPalette: PaletteColorOptions = targetIsPublicPatient
  ? {
      main: '#F08317',
      contrastText: '#FFFFFF',
      light: '#FCE6CF',
    }
  : {
      main: '#165E83',
      contrastText: '#FFFFFF',
      light: '#D3ECF8',
    };

export const currentOmamoriVersion = (): string => {
  return isProduction
    ? `v${rootPackage.version}-public-β`
    : `v${rootPackage.version}-non-production`;
};
