import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { PagePaths as p } from 'pagepaths';
import { isProduction } from 'env';
import AuthRoute from 'routes/AuthRoute';
import AppSwitch from 'routes/AppSwitch';
import Test from 'pages/Test';

const Top = loadable(() => import('pages/Top'));
const Welcome = loadable(() => import('pages/welcome/Welcome'));
const TermsOfService = loadable(() => import('pages/TermsOfService'));
const PrivacyPolicy = loadable(() => import('pages/PrivacyPolicy'));
const Tokushouhou = loadable(() => import('pages/Tokushouhou'));
const CancelPolicy = loadable(() => import('pages/CancelPolicy'));
const Clear = loadable(() => import('pages/Clear'));
const Counselor = loadable(() => import('pages/app/search/Counselor'));

const AccountSwitch = loadable(() => import('routes/AccountSwitch'));
const AuthSwitch = loadable(() => import('routes/AuthSwitch'));

// const Test = loadable(() => import('pages/Test'));
const NotFound = loadable(() => import('pages/NotFound'));

const App = () => (
  <Switch>
    <Route exact path={p.root} component={Top} />
    <Route exact path={p.welcome} component={Welcome} />
    <Route exact path={p.termsOfService} component={TermsOfService} />
    <Route exact path={p.privacyPolicy} component={PrivacyPolicy} />
    <Route exact path={p.tokushouhou} component={Tokushouhou} />
    <Route exact path={p.cancelPolicy} component={CancelPolicy} />
    <Route exact path={p.clear} component={Clear} />
    <Route exact path={p.counselor + '/:id'} component={Counselor} />

    {/** アカウント情報が足りない関連 */}
    <Route path={p.account} component={AccountSwitch} />

    <Route path={p.auth} component={AuthSwitch} />
    <AuthRoute path={p.app} component={AppSwitch} />

    {/* リリース時には参照されない */}
    {!isProduction && <Route exact path={p.test} component={Test} />}

    <Route component={NotFound} />
  </Switch>
);

export default App;
