import Auth from '@aws-amplify/auth';
import GraphQLAPI from '@aws-amplify/api-graphql';

import awsConfigProd from 'assets/configs/awsConfig.production.json';
import awsConfigDev from 'assets/configs/awsConfig.development.json';
import awsConfigTest from 'assets/configs/awsConfig.test.json';
import checkStage from 'lib/checkStage';
import logger from 'lib/logger';

declare type AWSConfig = {
  Auth: {
    region: string;
    identityPoolId: string;
    userPoolId: string;
    userPoolWebClientId: string;
    userPoolDomain: string;
  };
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_appsync_apiKey: string;
  stripePublishableKey: string;
};

export const getAwsConfig = async () => {
  const stage = checkStage();
  if (stage === 'production') return awsConfigProd;
  if (stage === 'development') return awsConfigDev;
  if (stage === 'test') return awsConfigTest;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return import(`assets/awsConfig.${stage}.json`)
    .then((config) => config.default)
    .catch(logger.error) as unknown as AWSConfig;
};

const loadAwsConfig = async () => {
  logger.log('loading awsConfig');
  const config = await getAwsConfig();

  if (config) {
    Auth.configure(config.Auth);
    GraphQLAPI.configure(config);
  }
};

export default loadAwsConfig;
