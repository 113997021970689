import React from 'react';

import {
  Avatar,
  Backdrop,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';

import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
//import HomeWorkIcon from '@mui/icons-material/HomeWork';

import { useProfile } from 'db';
import { useHistory } from 'react-router';
import PagePaths from 'pagepaths';
import FlexContainer from 'layouts/FlexContainer';
import { targetIsPublicPatient } from 'env';
import { displayName } from '@web/lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    margin: theme.spacing(2),
  },
}));

interface MyPageListItemProps {
  Icon: React.ReactNode;
  text: string;
  href?: string;
}

const MyPageListItem: React.FC<MyPageListItemProps> = ({
  Icon,
  text,
  href,
}) => {
  const history = useHistory();
  const handleClick = () => href && history.push(href);
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" onClick={handleClick}>
          <ChevronRightIcon />
        </IconButton>
      }
      disablePadding
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{Icon}</ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

const Mypage: React.FC = () => {
  const classes = useStyles();
  const profile = useProfile();

  const profileImage = profile?.privateUser?.profileImage ?? '';
  const ProfileAvatar = () => (
    <Avatar className={classes.avatar} src={profileImage}>
      {profile?.privateUser ? displayName(profile.privateUser) : ''}
    </Avatar>
  );
  const fullname = profile?.privateUser
    ? displayName(profile.privateUser)
    : 'プロファイルが見つかりません';

  return profile ? (
    <>
      <Box className={classes.container}>
        <ProfileAvatar />
        <Box>
          <Typography variant="h4">{fullname}</Typography>
          <Typography>ID: {profile?.cognitoUser.username}</Typography>
        </Box>
      </Box>
      <FlexContainer component={Container} maxWidth="sm" sx={{ padding: 0 }}>
        <List disablePadding>
          <MyPageListItem
            Icon={<EditIcon />}
            text="プロフィールを確認・編集する"
            href={
              targetIsPublicPatient
                ? PagePaths.updateAttributes
                : PagePaths.counselor + '/' + profile.id
            }
          />
          {targetIsPublicPatient ? (
            <>
              <MyPageListItem
                Icon={<ChatIcon />}
                text="悩みを投稿する"
                href={PagePaths.concern}
              />
              <MyPageListItem
                Icon={<EventNoteIcon />}
                text="予約一覧"
                href={PagePaths.myCalendar}
              />
            </>
          ) : (
            <>
              <MyPageListItem
                Icon={<AddCardIcon />}
                text="新しい資格証明書を登録する"
                href={PagePaths.registerQualificationEvidences}
              />
              <MyPageListItem
                Icon={<DateRangeIcon />}
                text="スケジュールを確認・登録する"
                href={PagePaths.myCalendar}
              />
              <MyPageListItem
                Icon={<ReceiptIcon />}
                text="料金プランを確認・作成する"
                href={PagePaths.myPlan}
              />
              <MyPageListItem
                Icon={<EventNoteIcon />}
                text="予約一覧"
                href={PagePaths.myReservations}
              />
              <MyPageListItem
                Icon={<PeopleIcon />}
                text="クライエント一覧"
                href={PagePaths.myClients}
              />
              {/* <MyPageListItem
                Icon={<HomeWorkIcon />}
                text="ルームを確認・編集する"
                href={PagePaths.counselor + '/' + profile.id}
              /> */}
            </>
          )}
          <MyPageListItem
            Icon={<SettingsIcon />}
            text="設定"
            href={PagePaths.setting}
          />
        </List>
      </FlexContainer>
    </>
  ) : (
    <Backdrop open={true}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Mypage;
