import * as TypedNodeDocument from '@libs/share/graphql-interfaces/typed-document-node';

import loadAwsConfig from 'awsConfig';
import logger from 'lib/logger';
import {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import AuthService from 'services/auth-service';

loadAwsConfig();

class GraphQLService {
  private async getApolloClient(): Promise<
    ApolloClient<NormalizedCacheObject>
  > {
    const link = await AuthService.getApolloLinkForAppSync();
    const apolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });
    return apolloClient;
  }
  private async getApolloClientApiKey(): Promise<
    ApolloClient<NormalizedCacheObject>
  > {
    const link = await AuthService.getApolloLinkForAppSyncApiKey();
    const apolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });
    return apolloClient;
  }

  private logResponse(
    res:
      | ApolloQueryResult<unknown>
      | FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>
  ): void {
    if (res.errors) {
      logger.error(res);
    }
    if (!res.data) {
      logger.error('response data is empty');
    }
    if (res.data) {
      logger.log(JSON.stringify(res.data));
    }
  }

  // Query
  async _getUser(args: TypedNodeDocument.QueryGetUserArgs) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetUserDocument,
      variables: { ...args },
    });
    this.logResponse(res);

    return res.data.getUser;
  }

  async listActiveCounselors(
    args: TypedNodeDocument.QueryListActiveCounselorsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListActiveCounselorsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listActiveCounselors;
  }

  private async listIncomingMessages(
    args: TypedNodeDocument.QueryListIncomingMessagesArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListIncomingMessagesDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listIncomingMessages;
  }

  private async listOutgoingMessages(
    args: TypedNodeDocument.QueryListOutgoingMessagesArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListOutgoingMessagesDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listOutgoingMessages;
  }

  async listSelfReservations(
    args: TypedNodeDocument.QueryListSelfReservationsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListSelfReservationsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listSelfReservations;
  }

  async listSelfCalendarItems(
    args: TypedNodeDocument.QueryListSelfCalendarItemsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListSelfCalendarItemsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listSelfCalendarItems;
  }

  async getReservation(args: TypedNodeDocument.QueryGetReservationArgs) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetReservationDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.getReservation;
  }

  async getPlan(args: TypedNodeDocument.QueryGetPlanArgs) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetPlanDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.getPlan;
  }

  async listCounselorPlans(
    args: TypedNodeDocument.QueryListCounselorPlansArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListCounselorPlansDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listCounselorPlans;
  }

  async getMessage(args: TypedNodeDocument.QueryGetMessageArgs) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetMessageDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.getMessage;
  }

  async getCounselingRoom(args: TypedNodeDocument.QueryGetCounselingRoomArgs) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetCounselingRoomDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.getCounselingRoom;
  }

  async createCounselingJwt(
    args: TypedNodeDocument.QueryCreateCounselingJwtArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.CreateCounselingJwtDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.createCounselingJwt;
  }

  async createPutObjectPresignedUrl(
    args: TypedNodeDocument.QueryCreatePutObjectPresignedUrlArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.CreatePutObjectPresignedUrlDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    if (!res.data.createPutObjectPresignedUrl?.presignedUrl) {
      throw new Error('presignedUrl was not returned.');
    } else {
      return res.data.createPutObjectPresignedUrl.presignedUrl;
    }
  }

  async createPaymentAccountOnboardingUrl(
    args: TypedNodeDocument.QueryCreatePaymentAccountOnboardingUrlArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.CreatePaymentAccountOnboardingUrlDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.createPaymentAccountOnboardingUrl;
  }

  async createPaymentSession(
    args: TypedNodeDocument.QueryCreatePaymentSessionArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.CreatePaymentSessionDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.createPaymentSession;
  }

  async listCounselorReservations(
    args: TypedNodeDocument.QueryListCounselorReservationsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListCounselorReservationsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listCounselorReservations;
  }

  async listCounselorAvailableTimes(
    args: TypedNodeDocument.QueryListCounselorAvailableTimesArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListCounselorAvailableTimesDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listCounselorAvailableTimes;
  }

  async listCounselorCalendarItems(
    args: TypedNodeDocument.QueryListCounselorCalendarItemsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListCounselorCalendarItemsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listCounselorCalendarItems;
  }

  async listTimelineConcerns(
    args: TypedNodeDocument.QueryListTimelineConcernsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListTimelineConcernsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listTimelineConcerns;
  }

  async listPatientReservations(
    args: TypedNodeDocument.QueryListPatientReservationsArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.ListPatientReservationsDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.listPatientReservations;
  }

  async getProgressNote(args: TypedNodeDocument.QueryGetProgressNoteArgs) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetProgressNoteDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.getProgressNote;
  }

  async getProgressNoteByReservationId(
    args: TypedNodeDocument.QueryGetProgressNoteByReservationIdArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.query({
      query: TypedNodeDocument.GetProgressNoteByReservationIdDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data.getProgressNoteByReservationId;
  }

  // Mutation

  async createUser(args: TypedNodeDocument.MutationCreateUserArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.CreateUserDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.createUser;
  }

  async updatePatient(args: TypedNodeDocument.MutationUpdatePatientArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.UpdatePatientDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.updatePatient;
  }

  async updateCounselor(args: TypedNodeDocument.MutationUpdateCounselorArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.UpdateCounselorDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.updateCounselor;
  }

  async postConcern(args: TypedNodeDocument.MutationPostConcernArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.PostConcernDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.postConcern;
  }

  async postComment(args: TypedNodeDocument.MutationPostCommentArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.PostCommentDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.postComment;
  }

  async postChat(args: TypedNodeDocument.MutationPostChatArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.PostChatDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.postChat;
  }

  async cancelReservation(
    args: TypedNodeDocument.MutationCancelReservationArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.CancelReservationDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.cancelReservation;
  }

  async updateReservation(
    args: TypedNodeDocument.MutationUpdateReservationArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.UpdateReservationDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.updateReservation;
  }

  async createPlan(args: TypedNodeDocument.MutationCreatePlanArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.CreatePlanDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.createPlan;
  }

  async updatePlan(args: TypedNodeDocument.MutationUpdatePlanArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.UpdatePlanDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.updatePlan;
  }

  async deletePlan(args: TypedNodeDocument.MutationDeletePlanArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.DeletePlanDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.deletePlan;
  }

  async createAvailableTime(
    args: TypedNodeDocument.MutationCreateAvailableTimeArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.CreateAvailableTimeDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.createAvailableTime;
  }

  async deleteAvailableTime(
    args: TypedNodeDocument.MutationDeleteAvailableTimeArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.DeleteAvailableTimeDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.deleteAvailableTime;
  }

  async createProgressNote(
    args: TypedNodeDocument.MutationCreateProgressNoteArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.CreateProgressNoteDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.createProgressNote;
  }

  async updateProgressNote(
    args: TypedNodeDocument.MutationUpdateProgressNoteArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.UpdateProgressNoteDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.updateProgressNote;
  }

  async fixProgressNote(args: TypedNodeDocument.MutationFixProgressNoteArgs) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.FixProgressNoteDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.fixProgressNote;
  }

  async addPostscriptToProgressNote(
    args: TypedNodeDocument.MutationAddPostscriptToProgressNoteArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.AddPostscriptToProgressNoteDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.addPostscriptToProgressNote;
  }

  async createCounselingRoom(
    args: TypedNodeDocument.MutationCreateCounselingRoomArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.CreateCounselingRoomDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.createCounselingRoom;
  }

  async updateCounselingRoom(
    args: TypedNodeDocument.MutationUpdateCounselingRoomArgs
  ) {
    const client = await this.getApolloClient();
    const res = await client.mutate({
      mutation: TypedNodeDocument.UpdateCounselingRoomDocument,
      variables: { ...args },
    });
    this.logResponse(res);
    return res.data?.updateCounselingRoom;
  }

  async postedChat(
    args: TypedNodeDocument.SubscriptionPostedChatArgs,
    callback: (_message?: TypedNodeDocument.Chat) => void
  ) {
    const client = await this.getApolloClient();

    const connect = client.subscribe({
      query: TypedNodeDocument.PostedChatDocument,
      variables: {
        ...args,
      },
    });

    return connect.subscribe((res) => {
      if (res.errors) {
        logger.error(res.errors.toString());
      } else if (!res.data || !res.data?.postedChat) {
        logger.error('response data is empty');
      } else {
        callback(res.data?.postedChat);
      }
    }, logger.error);
  }

  // Custom APIs
  async listIncomingMessagesAll(
    args: TypedNodeDocument.QueryListIncomingMessagesArgs
  ) {
    const messages = new Array<TypedNodeDocument.Message>();
    let nextToken: string | undefined;
    do {
      const res = await this.listIncomingMessages({ ...args, nextToken });
      if (res) {
        // 今後条件で絞り込むなど、null が紛れ込むようになるとエラーになる
        messages.push(...(res.messages as TypedNodeDocument.Message[]));
        nextToken = res.nextToken ?? undefined;
      } else {
        break;
      }
    } while (nextToken);
    return messages;
  }

  async listOutgoingMessagesAll(
    args: TypedNodeDocument.QueryListOutgoingMessagesArgs
  ) {
    const messages = new Array<TypedNodeDocument.Message>();
    let nextToken: string | undefined;
    do {
      const res = await this.listOutgoingMessages({ ...args, nextToken });
      if (res) {
        // 今後条件で絞り込むなど、null が紛れ込むようになるとエラーになる
        messages.push(...(res.messages as TypedNodeDocument.Message[]));
        nextToken = res.nextToken ?? undefined;
      } else {
        break;
      }
    } while (nextToken);
    return messages;
  }

  // Public API with API Key
  async getCounselor(args: TypedNodeDocument.QueryGetCounselorArgs) {
    const client = await this.getApolloClientApiKey();
    const res = await client.query({
      query: TypedNodeDocument.GetCounselorDocument,
      variables: { ...args },
    });
    this.logResponse(res);

    return res.data.getCounselor;
  }
}

const API = new GraphQLService();
export default API;
