import {
  User,
  PublicUser,
  PrivateUser,
  PublicPatient,
  PublicCounselor,
  PrivateCounselor,
  PrivatePatient,
  AiAssistant,
  CalendarItem,
  PublicCalendarItem,
  AvailableTime,
  PrivateInAppReservation,
  PublicInAppReservation,
  Plan,
  QualificationEvidence,
  ConfirmedCppQualificationEvidence,
  ConfirmedCpQualificationEvidence,
  UnconfirmedQualificationEvidence,
  Message,
  TextMessage,
  Comment,
  Concern,
  Reservation,
  FileMessage,
  ImageMessage,
  Chat,
} from '@libs/share/graphql-interfaces/typed-document-node';
interface GraphQLObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

// User

export const isUser = (
  item: null | undefined | GraphQLObject
): item is User => {
  return isPublicUser(item) || isPrivateUser(item);
};
export const isCounselor = (
  item: null | undefined | GraphQLObject
): item is PublicCounselor | PrivateCounselor => {
  return isPublicCounselor(item) || isPrivateCounselor(item);
};
export const isPatient = (
  item: null | undefined | GraphQLObject
): item is PublicPatient | PrivatePatient => {
  return isPublicPatient(item) || isPrivatePatient(item);
};

export const isPublicUser = (
  item: null | undefined | GraphQLObject
): item is PublicUser => {
  return isPublicPatient(item) || isPublicCounselor(item);
};

export const isPrivateUser = (
  item: null | undefined | GraphQLObject
): item is PrivateUser => {
  return isPrivatePatient(item) || isPrivateCounselor(item);
};

export const isPublicPatient = (
  item: null | undefined | GraphQLObject
): item is PublicPatient => {
  return item?.__typename != null && item.__typename === 'PublicPatient';
};
export const isPublicCounselor = (
  item: null | undefined | GraphQLObject
): item is PublicCounselor => {
  return item?.__typename != null && item.__typename === 'PublicCounselor';
};
export const isPrivatePatient = (
  item: null | undefined | GraphQLObject
): item is PrivatePatient => {
  return item?.__typename != null && item.__typename === 'PrivatePatient';
};
export const isPrivateCounselor = (
  item: null | undefined | GraphQLObject
): item is PrivateCounselor => {
  return item?.__typename != null && item.__typename === 'PrivateCounselor';
};

export const isConfirmedCPPQualificationEvidence = (
  item: null | undefined | GraphQLObject
): item is ConfirmedCppQualificationEvidence => {
  return (
    item?.__typename != null &&
    item.__typename === 'ConfirmedCPPQualificationEvidence'
  );
};
export const isConfirmedCPQualificationEvidence = (
  item: null | undefined | GraphQLObject
): item is ConfirmedCpQualificationEvidence => {
  return (
    item?.__typename != null &&
    item.__typename === 'ConfirmedCPQualificationEvidence'
  );
};
export const isUnconfirmedQualificationEvidence = (
  item: null | undefined | GraphQLObject
): item is UnconfirmedQualificationEvidence => {
  return (
    item?.__typename != null &&
    item.__typename === 'UnconfirmedQualificationEvidence'
  );
};
export const isConfirmedQualificationEvidence = (
  item: null | undefined | GraphQLObject
): item is
  | ConfirmedCpQualificationEvidence
  | ConfirmedCppQualificationEvidence => {
  return (
    isConfirmedCPPQualificationEvidence(item) ||
    isConfirmedCPQualificationEvidence(item)
  );
};
export const isQualificationEvidence = (
  item: null | undefined | GraphQLObject
): item is QualificationEvidence => {
  return (
    isConfirmedCPPQualificationEvidence(item) ||
    isConfirmedCPQualificationEvidence(item) ||
    isUnconfirmedQualificationEvidence(item)
  );
};
export const isAiAssistant = (
  item: null | undefined | GraphQLObject
): item is AiAssistant => {
  return item?.__typename != null && item.__typename === 'AiAssistant';
};
export const isHumanUser = (
  item: null | undefined | GraphQLObject
): item is
  | PublicCounselor
  | PrivateCounselor
  | PublicPatient
  | PrivatePatient => {
  return isPatient(item) || isCounselor(item);
};

// Service

export const isAvailableTime = (
  item: null | undefined | GraphQLObject
): item is AvailableTime => {
  return item?.__typename != null && item.__typename === 'AvailableTime';
};
export const isPrivateInAppReservation = (
  item: null | undefined | GraphQLObject
): item is PrivateInAppReservation => {
  return (
    item?.__typename != null && item.__typename === 'PrivateInAppReservation'
  );
};
export const isPublicInAppReservation = (
  item: null | undefined | GraphQLObject
): item is PublicInAppReservation => {
  return (
    item?.__typename != null && item.__typename === 'PublicInAppReservation'
  );
};
export const isPrivateCalendarItem = (
  item: null | undefined | GraphQLObject
): item is CalendarItem => {
  return isPrivateInAppReservation(item) || isAvailableTime(item);
};
export const isPublicCalendarItem = (
  item: null | undefined | GraphQLObject
): item is PublicCalendarItem => {
  return isPublicInAppReservation(item) || isAvailableTime(item);
};
export const isCanceledReservation = (
  item: null | undefined | GraphQLObject
): boolean => {
  return (
    isPrivateInAppReservation(item) && item.reservationStatus === 'canceled'
  );
};
export const isWithinReservation = (a: AvailableTime) => (r: Reservation) =>
  (a.startTimestamp <= r.startTimestamp &&
    r.startTimestamp <= a.endTimestamp) ||
  (a.startTimestamp <= r.endTimestamp && r.endTimestamp <= a.endTimestamp);

// Plan

export const isPlan = (
  item: null | undefined | GraphQLObject
): item is Plan => {
  return item?.__typename != null && item.__typename === 'Plan';
};

export const isNotDeletedPlan = (
  item: null | undefined | GraphQLObject
): boolean => {
  return isPlan(item) && item.deleted !== true;
};

// Message

export const isMessage = (
  item: null | undefined | GraphQLObject
): item is Message => {
  return (
    isTextMessage(item) ||
    isFileMessage(item) ||
    isConcern(item) ||
    isComment(item)
  );
};

export const isChat = (
  item: null | undefined | GraphQLObject
): item is Chat => {
  return isTextMessage(item) || isFileMessage(item) || isImageMessage(item);
};

export const isTextMessage = (
  item: null | undefined | GraphQLObject
): item is TextMessage => {
  return (
    item?.__typename != null &&
    // TODO: Remove Chat type while after migration. This is for backward compatibility of browser cache.
    (item.__typename === 'TextMessage' || item.__typename === 'Chat')
  );
};

export const isFileMessage = (
  item: null | undefined | GraphQLObject
): item is FileMessage => {
  return item?.__typename != null && item.__typename === 'FileMessage';
};

export const isImageMessage = (
  item: null | undefined | GraphQLObject
): item is ImageMessage => {
  return item?.__typename != null && item.__typename === 'ImageMessage';
};

export const isConcern = (
  item: null | undefined | GraphQLObject
): item is Concern => {
  return item?.__typename != null && item.__typename === 'Concern';
};

export const isComment = (
  item: null | undefined | GraphQLObject
): item is Comment => {
  return item?.__typename != null && item.__typename === 'Comment';
};
