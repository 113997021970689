import { RouteProps, Redirect, Route } from 'react-router-dom';

import { Backdrop, CircularProgress } from '@mui/material';

import { targetIsPublicPatient } from 'env';
import { PagePaths as p } from 'pagepaths';
import AuthService from 'services/auth-service';
import { clearProfile, useProfile } from 'db';
import { clearDb, isValidDataOwner } from '../db/actions';
import logger from 'lib/logger';
import RedirectRoute from 'routes/RedirectRoute';
import { useSnackbar } from 'components/SnackbarProvider';
import { PrivateCounselor } from '@libs/share/graphql-interfaces/typed-document-node';
import {
  isConfirmedCPPQualificationEvidence,
  isConfirmedCPQualificationEvidence,
  isPrivateCounselor,
} from '../graphql/discriminator';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';

dayjs.extend(timezone);
dayjs.extend(utc);

const AuthRoute = (props: RouteProps) => {
  const profile = useProfile();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (profile) {
      AuthService.currentAuthenticatedUser()
        .then((user) => {
          const nowTimestamp = dayjs().unix();
          if (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (user as any).signInUserSession.accessToken.payload?.exp <
            nowTimestamp
          ) {
            logger.warn("Current user's access token is expired.");
            clearProfile();
          }
          if (user) return isValidDataOwner(user.attributes.sub);
          else return true;
        })
        .then((isVerified) => {
          if (!isVerified) {
            logger.log(
              'Cached user profile does not match authenticated user, Indexed Db is cleared.'
            );
            return clearDb();
          }
        });
    }
  });

  if (!profile) {
    if (profile === undefined) {
      // loading profile
      logger.log({ msg: 'AuthRoute', state: 'loading profile' });
      return (
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      // 未ログイン
      logger.log({ msg: 'AuthRoute', state: 'user not logged in' });
      logger.log({ msg: 'AuthRoute', to: p.root });
      return <Redirect to={p.root} />;
    }
  }

  const { cognitoUser } = profile;
  // 電話番号認証がまだのユーザー
  if (!cognitoUser.attributes.phone_number_verified) {
    logger.log({ msg: 'AuthRoute', to: p.verifyPhone });
    enqueueSnackbar({
      message: '電話番号認証を完了してください',
      variant: 'success',
    });
    return <Redirect to={p.verifyPhone} />;
  }

  // カウンセラーかつメール認証がまだのユーザー
  if (!targetIsPublicPatient && !cognitoUser.attributes.email_verified) {
    logger.log({ msg: 'AuthRoute', to: p.verifyEmail });
    AuthService.verifyCurrentUserAttribute('email');
    enqueueSnackbar({
      message: 'メール認証を完了してください',
      variant: 'success',
    });
    return <Redirect to={p.verifyEmail} />;
  }

  // カウンセラーかつCognito属性登録がまだのユーザー
  if (!targetIsPublicPatient && !cognitoUser.attributes.birthdate) {
    logger.log({ msg: 'AuthRoute', to: p.registerAttributes });
    return <RedirectRoute {...props} to={p.registerAttributes} />;
  }

  const { privateUser } = profile;
  // ユーザー情報登録がまだのユーザー
  if (!privateUser) {
    const to = targetIsPublicPatient
      ? p.registerAttributes
      : p.registerStripeAccount;
    logger.log({ msg: 'AuthRoute', to });
    return <RedirectRoute {...props} to={to} />;
  }

  // カウンセラーかつ情報不足のため Stripe アカウントが無効化されているユーザー
  if (
    !targetIsPublicPatient &&
    (privateUser as PrivateCounselor).stripeCurrentDisabledReason === 'past_due'
  ) {
    logger.log({ msg: 'AuthRoute', to: p.registerStripeAccount });
    return <RedirectRoute {...props} to={p.registerStripeAccount} />;
  }

  // ban されたユーザ
  if (privateUser.ban) {
    return <RedirectRoute {...props} to={p.banned} />;
  }

  type QualificationRegistrationStatus =
    | 'no_qualification'
    | 'pending'
    | 'valid_qualification'
    | 'other';
  const qualificationRegistrationStatus = (
    counselor: PrivateCounselor
  ): QualificationRegistrationStatus => {
    if (
      !counselor.qualificationEvidences ||
      counselor.qualificationEvidences.length === 0
    ) {
      return 'no_qualification';
    }
    const qualificationEvidences = counselor.qualificationEvidences;
    const nowTimestamp = dayjs().unix();
    const validEvidences = qualificationEvidences.filter((evidence) => {
      return (
        (isConfirmedCPQualificationEvidence(evidence) &&
          evidence.expiredTimestamp > nowTimestamp) ||
        isConfirmedCPPQualificationEvidence(evidence)
      );
    });
    if (validEvidences.length) {
      return 'valid_qualification';
    }
    const pendingEvidences = qualificationEvidences.filter((evidence) => {
      return evidence.evidenceConfirmationStatus === 'uploaded';
    });
    if (pendingEvidences.length) {
      return 'pending';
    }
    return 'other';
  };

  // カウンセラーかつ資格確認中のユーザ
  if (
    isPrivateCounselor(privateUser) &&
    qualificationRegistrationStatus(privateUser) === 'pending'
  ) {
    logger.log({
      msg: 'AuthRoute',
      to: p.waitQualificationEvidenceConfirmation,
    });
    return (
      <RedirectRoute {...props} to={p.waitQualificationEvidenceConfirmation} />
    );
  }

  // カウンセラーかつ資格情報登録済みかつ資格エビデンス登録がまだのユーザー
  if (
    isPrivateCounselor(privateUser) &&
    qualificationRegistrationStatus(privateUser) === 'no_qualification'
  ) {
    logger.log({ msg: 'AuthRoute', to: p.registerQualificationEvidences });
    return <RedirectRoute {...props} to={p.registerQualificationEvidences} />;
  }

  // カウンセラーかつ Stripe の承認がまだのユーザ
  if (
    !targetIsPublicPatient &&
    (privateUser as PrivateCounselor).stripeCurrentDisabledReason ===
      'pending_verification'
  ) {
    logger.log({ msg: 'AuthRoute', to: p.waitStripeAccountConfirmation });
    return <RedirectRoute {...props} to={p.waitStripeAccountConfirmation} />;
  }

  // ログイン済みでトップページを表示しようとしたとき
  if (props.path === p.root) {
    logger.log({ msg: 'AuthRoute', to: p.app });
    return <Redirect to={p.app} />;
  }

  logger.log({ msg: 'AuthRoute', pass: true, to: props.location?.pathname });
  return <Route {...props} />;
};

export default AuthRoute;
