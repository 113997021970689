import React from 'react';

import { Backdrop, CircularProgress, Typography } from '@mui/material';

interface LoadingProps {
  open: boolean;
  text?: string;
}

const Loading: React.FC<LoadingProps> = ({ open, text }) => {
  return (
    // https://mui.com/material-ui/customization/z-index/
    <Backdrop
      open={open}
      sx={{ color: '#fff', zIndex: 2000, flexDirection: 'column' }}
    >
      <CircularProgress color="inherit" sx={{ m: 2 }} />
      <Typography>{text}</Typography>
    </Backdrop>
  );
};

export default Loading;
