import React from 'react';
import { Avatar, Box, styled, Typography } from '@mui/material';

import {
  PublicCounselor,
  PrivateCounselor,
} from '@libs/share/graphql-interfaces/typed-document-node';
import PagePaths from 'pagepaths';
import FlexContainer from 'layouts/FlexContainer';
import RenderLink from './RenderLink';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { uniqueQualificationNames } from 'lib/uniqueQualificationNames';
import { displayName } from '@web/lib/utils';

dayjs.extend(timezone);
dayjs.extend(utc);

const Description = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

interface CounselorInSearchProps {
  counselor: PublicCounselor;
}
const CounselorInSearch: React.FC<CounselorInSearchProps> = ({ counselor }) => {
  const qualifications = (
    counselor: PrivateCounselor | PublicCounselor
  ): string[] => {
    if (
      !counselor.qualificationEvidences ||
      counselor.qualificationEvidences.length === 0
    ) {
      return [];
    }
    return uniqueQualificationNames(counselor.qualificationEvidences);
  };

  return (
    <FlexContainer
      sx={{
        boxShadow: 4,
        borderRadius: 3,
        margin: 1,
        padding: 2,
        flex: 0,
        maxHeight: 200,
      }}
      component={RenderLink(PagePaths.counselor + '/' + counselor.id)}
    >
      <Box sx={{ display: 'flex' }}>
        <Avatar
          src={counselor.profileImage || ''}
          sx={{ width: '5rem', height: '5rem', mr: 2 }}
        >
          {displayName(counselor)}
        </Avatar>
        <Box>
          <Typography variant="h5">{displayName(counselor)}</Typography>
          <Typography variant="body1">
            {qualifications(counselor)?.join(' / ')}
          </Typography>
        </Box>
      </Box>
      <Description variant="body1">{counselor.description}</Description>
    </FlexContainer>
  );
};

export default CounselorInSearch;
