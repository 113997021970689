import { User } from '@libs/share/graphql-interfaces/typed-document-node';
import { isCounselor, isPatient } from '@web/graphql/discriminator';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import crypto from 'crypto';

export function timeout(msec: number) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
export function uniqueArray<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

/**
 * 電話番号をグローバル表記(+81...)に変換
 */
export function convertPhoneNumberToGlobal(phoneNumber: string): string {
  return /^0\d{10}$/.test(phoneNumber)
    ? '+81' + phoneNumber.slice(1)
    : phoneNumber;
}
/**
 * 電話番号をローカル表記(090...)に変換
 */
export function convertPhoneNumberToLocal(phoneNumber: string): string {
  return /^\+81\d{10}$/.test(phoneNumber)
    ? '0' + phoneNumber.slice(3)
    : phoneNumber;
}

/**
 * 数字の左を 0 埋めする
 * CAUTION: num is sliced when num.length > len
 */
export function zeroPadding(num: number, len: number): string {
  return (Array(len).join('0') + num).slice(-len);
}

/**
 * 文字列がひらがなか判定する
 */
export function isHiragana(str: string): boolean {
  return /^[\u3040-\u309F]+$/.test(str);
}

/**
 * 文字列が全角カタカナか判定する
 */
export function isZenkakuKana(str: string) {
  return /^[\u30A0-\u30FF]+$/.test(str);
}

/**
 * 文字列が半角カタカナか判定する
 */
export function isHankakuKana(str: string) {
  return /^[\uFF61-\uFF9F]+$/.test(str);
}

/**
 * 文字列が郵便番号の形式が判定する
 */
export function isValidZipCode(zipCode: string): boolean {
  return /^\d{7}$/.test(zipCode);
}

export function hash(data: string) {
  return crypto.createHash('md5').update(data).digest('hex');
}

const cleaner = (key: string, value: unknown) =>
  key === '__proto__' ? undefined : value;

/** prototype 継承させずに JSON.parse する */
export function cleanJsonParse(data: string): any {
  return JSON.parse(data, cleaner);
}

/**
 * 日付のみのタイムスタンプを取得
 */
export const getDateTimestamp = (ts: number): number =>
  dayjs(dayjs.unix(ts).format('YYYY/MM/DD'), 'YYYY/MM/DD').unix();

/**
 * タイムスタンプを`時:分`に変換
 * 24:00 を超えて表示できる
 * NOTICE: You need to input time difference from the reference date like `dayjs().unix() - getDateTimestamp(dayjs().unix())
 * TODO: input the reference date or timestamp as 2nd parameter
 */
export const timestamp2time = (ts: number): string => {
  const seconds = ts % 60;
  ts = (ts - seconds) / 60;
  const minutes = ts % 60;
  const hours = (ts - minutes) / 60;
  return `${zeroPadding(hours, 2)}:${zeroPadding(minutes, 2)}`;
};

/**
 * ユーザーの表示名を返す
 */
export const displayName = (user: User): string => {
  if (isCounselor(user)) {
    return user.displayName ?? `${user.lastName} ${user.firstName}`;
  } else if (isPatient(user)) {
    return user.firstName && user.lastName
      ? user.lastName + ' ' + user.firstName
      : user.username;
  } else {
    return user.username;
  }
};
