import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Container, IconButton, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReplyIcon from '@mui/icons-material/Reply';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';

import PagePaths from 'pagepaths';
import { listTimelineConcerns, useTimeline } from 'controllers';
import FlexContainer from 'layouts/FlexContainer';

dayjs.locale('ja');
dayjs.extend(relativeTime);

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    padding: 20,
    paddingBottom: 100,
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  content: {
    width: '100%',
    flexShrink: 0,
    marginBottom: 10,
    padding: 10,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    textAlign: 'start',
  },
  main: {
    width: '100%',
    flexGrow: 1,
  },
  time: {
    width: '100%',
  },
  icon: {
    padding: 5,
  },
}));

const Timeline: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const concerns = useTimeline();

  useEffect(() => {
    listTimelineConcerns();
  }, []);

  const handleClickReply = (id: string) => () =>
    history.push(PagePaths.comment + '/' + id);

  return (
    <FlexContainer component={Container} maxWidth="sm">
      {concerns?.map((concern) => (
        <Paper key={concern.id} className={classes.content} elevation={5}>
          <Box className={classes.main}>
            <Typography variant="body1">
              {concern.text.slice(0, 100)}
            </Typography>
            <Typography variant="body2" className={classes.time} align="right">
              {dayjs.unix(concern.timestamp).fromNow()}
            </Typography>
          </Box>
          <IconButton
            onClick={handleClickReply(concern.id)}
            className={classes.icon}
            size="large"
          >
            <ReplyIcon color="primary" fontSize="large" />
          </IconButton>
        </Paper>
      ))}
    </FlexContainer>
  );
};

export default Timeline;
