import {
  AppConfigDataClient,
  StartConfigurationSessionCommand,
  StartConfigurationSessionCommandInput,
  GetLatestConfigurationCommand,
  GetLatestConfigurationCommandInput,
} from '@aws-sdk/client-appconfigdata';
import { Credentials, Provider } from '@aws-sdk/types';
import AuthService from './auth-service';
import { getAwsConfig } from 'awsConfig';
import {
  getFeatureFlag as getFeatureFlagFromDb,
  putFeatureFlag,
} from '../db/actions';
import { FeatureFlagBody } from '../db/models';
import { logger } from 'workbox-core/_private';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

const STAGE = process.env.REACT_APP_STAGE ?? 'test';

export class FeatureFlagService {
  private client: AppConfigDataClient;
  private region: string;
  private textDecoder: TextDecoder;
  private constructor(
    region: string,
    credentials: Credentials | Provider<Credentials>
  ) {
    this.textDecoder = new TextDecoder();
    this.region = region;
    this.client = new AppConfigDataClient({
      region,
      credentials,
    });
  }
  public static new = async (): Promise<FeatureFlagService> => {
    const awsConfig = await getAwsConfig();
    return new FeatureFlagService(
      awsConfig.aws_appsync_region,
      await AuthService.getCognitoCredentialForAwsSdk()
    );
  };

  public getToken = async (
    featureName: string,
    environment?: string
  ): Promise<string> => {
    const input: StartConfigurationSessionCommandInput = {
      ApplicationIdentifier: `Omamori-${STAGE}`,
      ConfigurationProfileIdentifier: featureName,
      EnvironmentIdentifier: environment ?? STAGE,
    };
    const command = new StartConfigurationSessionCommand(input);
    const response = await this.client.send(command);

    return response.InitialConfigurationToken ?? '';
  };

  // IndexedDB に Feature Flag のキャッシュを保存し、キャッシュヒットした場合は AppConfig と通信しない
  // キャッシュの TTL は actions.ts で定義
  public getFeatureFlag = async (
    params: FeatureFlagServiceGetFeatureFlagInput
  ): Promise<FeatureFlagBody> => {
    const flag = await getFeatureFlagFromDb(params.featureName);
    if (flag) {
      logger.log('feature flag cache hit');
      return flag.properties;
    } else {
      let token: string;
      if (params.token) {
        token = params.token;
      } else {
        token = await this.getToken(params.featureName, params.environment);
      }
      const input: GetLatestConfigurationCommandInput = {
        ConfigurationToken: token,
      };
      const command = new GetLatestConfigurationCommand(input);
      const response = await this.client.send(command);
      const body = this.textDecoder.decode(
        response.Configuration
      ) as unknown as FeatureFlagBody;
      await putFeatureFlag({
        name: params.featureName,
        properties: body,
        storedTimestamp: dayjs().unix(),
      });
      return body;
    }
  };
}

interface FeatureFlagServiceGetFeatureFlagInput {
  featureName: string;
  environment?: string;
  token?: string;
}
